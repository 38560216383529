import httpService from "./http.service";

const cardEndpoint = "cards";
const buyCardEndpoint = "cards/buy";

const cardService = {
  fetchAll: async () => {
    const { data } = await httpService.get(cardEndpoint);
    return data;
  },
  buy: async (payload: object) => {
    const { data } = await httpService.post(buyCardEndpoint, payload);
    return data;
  },
};

export default cardService;
