function getNamePunchById(id: string): string {
  switch (id) {
    case "1":
      return "Hit_Head";
    case "2":
      return "Hit_Body";
    case "3":
      return "Hit_High_L";
    case "4":
      return "Hit_High_R";
    case "5":
      return "Hit_Low_L";
    case "6":
      return "Hit_Low_R";
    default:
      return "Hit_Head";
  }
};
export default getNamePunchById;