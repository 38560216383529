import { CSSProperties, useState } from 'react';
import styles from './TrainingCard.module.css';
import { Card } from '../../../interfaces/card.type';
import convertNumber from '../../../utils/numbers';
import icons from '../../../utils/icons';
import CardConfirm from '../CardConfirm';
import { useTranslation } from 'react-i18next';

interface TrainingCardProps  {
  card: Card,
  disabled: boolean
  style?: CSSProperties
}

export default function TrainingCard({ card, disabled, style }:TrainingCardProps) {
  const { name, next_level, image, description, id, bg_color } = card;
  const { earning_increase, level, rules, upgrade_price } = next_level;
  const [show, setShow] = useState(false);
  const {t} = useTranslation('ns1');

  const handleOpenModal = () => {
    setShow(true);
  };
  
  return (
    <>
      <button
        onClick={handleOpenModal}
        className={styles.TrainingCard}
        style={style}
        disabled={disabled}
      >
        <div className={styles.Wrap}>
          <div className={styles.Image}>
            {disabled && <div className={styles.Lock}>
              <icons.lockIcon color="#fff" />
            </div>}
            <img src={image} alt={name} />
          </div>
          <div className={styles.Info}>
            <div className={styles.Title}>
              { name }
            </div>
            <div className={styles.Wrapper}>
              <div className={styles.Box}>
                <div className={styles.HeaderBox}>
                  {t('Level')}
                </div>
                <div className={styles.FooterBox}>
                  { level }
                </div>
              </div>
              <div className={styles.Separator}></div>
              <div className={styles.Box}>
                <div className={styles.HeaderBox}>
                  {t('Hourly earnings')}
                </div>
                <div className={styles.FooterBox}>
                  +{ convertNumber(earning_increase) }
                </div>
              </div>
                {disabled && <div className={styles.Separator}></div>}
                {disabled && <div className={styles.Impediment}>
                  { rules }
                </div>}
            </div>
          </div>
        </div>
        {!disabled &&<div className={styles.PriceInfo}>
          <div className={styles.ExpIcon}>
            <icons.xpIcon />
          </div>
          <div className={styles.Price}>
            { convertNumber(upgrade_price) }
          </div>
        </div>}
      </button>
      {!disabled && <CardConfirm
        id={id}
        label={name}
        text={description}
        imgPath={image}
        price={upgrade_price}
        profit={earning_increase}
        open={show}
        onClose={()=>setShow(false)}
        bgColor={bg_color}
      />}
    </>
  )
}
