import { useEffect } from 'react';
import styles from './FightPage.module.css';
import Navbar from '../../ui/Navbar';
import { fightEventItems as items } from '../../../utils/helpers';
import FightEventCard from '../../ui/FightEventCard';
import {FightWithActiveEvent} from '../../../interfaces/fight.type';
import MainEventCard from "../../ui/MainEventCard";
import { useTranslation } from 'react-i18next';
import {useAppSelector} from "../../../hooks/hooks";
import {getUserData} from "../../../store/user";

export default function FightPage() {
  const tg: WebApp = window.Telegram.WebApp;
  const sparringRewardExperience = useAppSelector(getUserData())?.user_league?.sparring?.reward_experience;
  const events = items.map(item => {
    if (item.context === 'sparring') {
      return {
        ...item,
        reward: sparringRewardExperience,
      }
    }

    return item;
  })
  const {t} = useTranslation('ns1');

  useEffect(() => {
    if (!tg.isExpanded) tg.expand();
  }, [tg.isExpanded]);

  return (
    <div className={styles.FightPage}>
      <h2 className={styles.Heading}>{t('Fights')}</h2>
      <div className={styles.Wrapper}>
        <h4>
          {t('Main event')}
          <i className={styles.Soon}>{t('Soon')}</i>
        </h4>
        <ul className={styles.List}>
          <MainEventCard
            title='Tournament'
            rank={55}
            disabled={true}
          />
        </ul>
        <h4>{t('Events')}</h4>
        <ul className={styles.List}>
        {events.map((item: FightWithActiveEvent) => (
          <FightEventCard
            key={item.id}
            title={item.title}
            reward={item?.reward}
            context={item.context}
            leftColor={item?.leftColor}
            rightColor={item?.rightColor}
            activeEvent={item.activeEvent}
            rewardType={item.rewardType}
          />
        ))}
        </ul>
      </div>
      <Navbar />
    </div>
  )
}
