import {CSSProperties, useState} from 'react';
import { createPortal } from 'react-dom';
import styles from './ChangeModal.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import getPathAndAlt from '../../../utils/pictures';
import convertNumber from '../../../utils/numbers';
import { SilverPack } from '../../../interfaces/silverPack.type';
import { useAppDispatch } from '../../../hooks/hooks';
import { purchasedSilver } from '../../../store/shop';
import ConfirmPurchase from '../ConfirmPurchase';
import { useTranslation } from 'react-i18next';
import {userInfo} from "../../../store/user";

type ShortSilverPack = Pick<SilverPack, "id" | "amount">;

type ChangeModalProps = {
  text: string,
  label: string,
  items: SilverPack[],
  options: ShortSilverPack[],
  open: boolean,
  onClose: (active: boolean) => void,
  style?: CSSProperties
};

export default function ChangeModal({ onClose, open, items, options, text, label, style }:ChangeModalProps ) {
  const dispatch = useAppDispatch();
  const tg: WebApp = window.Telegram.WebApp;
  const {t} = useTranslation('ns1');
  const [ show, setShow ] = useState(false);
  const { alt } = getPathAndAlt("stack");
  const { src: src2, alt: alt2 } = getPathAndAlt("XTR");
  const [active, setActive] = useState<number>(items[0].id);

  const displayListOptions = (opts: ShortSilverPack[]) => {
    if (opts.length === 0) return;
    return opts.map( (option) => {
      return (
        <button
          key={option.id}
          className={`${styles.Hit} ${active === option.id ? styles.active : ""}`}
          onClick={() => setActive(option.id)}
        >
          {option.amount}
        </button>
      )
    } )
  };

  const pickCurrentImg = (id: number) => {
    const currentPack = items.find((pack: SilverPack) => pack.id === id);
    return currentPack?.image
  };
  const pickCurrentPriceOfPack = (id: number) => {
    const currentPack = items.find((pack: SilverPack) => pack.id === id);
    return currentPack?.price_star
  };
  const pickCurrentAmount = (id: number) => {
    const currentPack = items.find((pack: SilverPack) => pack.id === id);
    if (!currentPack) return label;
    return currentPack.name;
  };

  const purchaseCallback = (status: "paid" | "cancelled" | "failed" | "pending") => {
    if (status === "paid") {
      setShow(true);
      setTimeout(() => {
        dispatch(userInfo());
      }, 5000);
    }
  };

  const handlePurchase = async() => {
    try {
      const response:any = await dispatch(purchasedSilver({
        coin_id: active,
        currency: "star"
      }));
      tg.openInvoice(response.invoiceLink, purchaseCallback as any);
    } catch (e) {
      console.log(e)
    }
  };

  return createPortal(
    <>
      <div className={`${styles.ChangeModal} ${open ? styles.show : ""}`} >
        <div
          className={styles.Overlay}
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
          }}
        />
        <div className={styles.Body} style={style}>
          <div className={styles.Header}>
          <button
              className={styles.Arrow}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              { icons.arrowIcon }
            </button>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Picture}>
              <img
                className={styles.Image}
                src={pickCurrentImg(active)}
                alt={alt}
              />
            </div>
            {label && <h1 className={styles.Title}>{t(`${label}`)}</h1>}
            {text && <div className={styles.Text}>{t(`${text}`)}</div>}
          </div>
          <div className={styles.HeaderBox}>
            {t('Quantity')}
          </div>
          <ul className={styles.List}>
            { displayListOptions(options) }
          </ul>
          <div className={styles.Info}>
            <div className={styles.Box}>
              <div className={styles.HeaderBox}>
                {t('Price')}
              </div>
              <div className={styles.PriceBox}>
                <div className={styles.CurrencyIcon}>
                  <img
                    className={styles.Icon}
                    src={src2}
                    alt={alt2}
                  />
                </div>
                <div className={styles.Price}>
                  { convertNumber(pickCurrentPriceOfPack(active)) } {t('stars')}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.WrapperSecond}>
            <MainButton
              heading='Buy'
              onTouch={handlePurchase}
            />
          </div>
        </div>
        <ConfirmPurchase
          label={pickCurrentAmount(active)}
          open={show}
          onClose={setShow}
        />
      </div>
    </>,
    document.getElementById('daily') as HTMLElement);
}
