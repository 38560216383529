import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./createStore";
import ratingService from "../services/rating.service";
import { ILeague } from "../interfaces/league.type";
import { IRating } from "../interfaces/rating.type";

// const mockLeagues = [
//   {
//     id: "junior_3",
//     title: "Юн.3",
//     rules: {
//       min_score: 0,
//       max_score: 1499
//     },
//     order: 1,
//     color: "#ded8ce",
//     bg_color: "#ded8ce",
//     image: "",
//     referral_reward: {
//       simple_silver: 1,
//       premium_silver: 2
//     },
//     sparring: {
//       reward_experience: 500,
//       tap_energy_max: 500,
//       tap_strength: 15,
//       knockdown_energy: 250
//     }
//   },
//   {
//     id: "junior_2",
//     title: "Юн.2",
//     rules: {
//       min_score: 1500,
//       max_score: 16499
//     },
//     order: 2,
//     color: "#ded8ce",
//     bg_color: "#ded8ce",
//     image: "",
//     referral_reward: {
//       simple_silver: 10,
//       premium_silver: 20
//     },
//     sparring: {
//       reward_experience: 1000,
//       tap_energy_max: 1500,
//       tap_strength: 3,
//       knockdown_energy: 250
//     }
//   }
// ];

type SliceState = {
  rating: null | IRating[],
  userRating: null | IRating,
  leagues: null | ILeague[],
  isLoading: boolean,
  error: any,
  dataRatingLoaded: boolean,
  dataLeaguesLoaded: boolean,
}

const initialState: SliceState = {
  rating: null,
  userRating: null,
  leagues: [],
  isLoading: false,
  error: null,
  dataRatingLoaded: false,
  dataLeaguesLoaded: false
};

const ratingSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {
    ratingRequested: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    ratingRequestSuccess: (state, action) => {
      state.rating = action.payload.usersRatingList;
      state.userRating = action.payload.userRating;
      state.isLoading = false;
      state.dataRatingLoaded = true;
    },
    ratingRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    leaguesRequested: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    leaguesRequestSuccess: (state, action) => {
      state.leagues = action.payload;
      state.isLoading = false;
      state.dataLeaguesLoaded = true;
    },
    leaguesRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

const { actions, reducer: ratingReducer } = ratingSlice;
const {
  ratingRequested,
  ratingRequestSuccess,
  ratingRequestFailed,
  leaguesRequested,
  leaguesRequestSuccess,
  leaguesRequestFailed
} = actions;


export const loadRatingLeague = (payload: string) => async (dispatch: any) => {
  dispatch(ratingRequested());
  try {
    const { content } = await ratingService.fetchById(payload);
    dispatch(ratingRequestSuccess(content));
  } catch (error: any) {
      dispatch(ratingRequestFailed(error.message));
  }
};
export const loadDataLeagues = () => async (dispatch: any) => {
  dispatch(leaguesRequested());
  try {
    const { content } = await ratingService.fetchAll();
    dispatch(leaguesRequestSuccess(content.leagues));
  } catch (error: any) {
      dispatch(leaguesRequestFailed(error.message));
  }
};

export const getRatingData = () => (state: RootState) => state.rating.rating;
export const getRatingUserData = () => (state: RootState) => state.rating.userRating;
export const getRatingLoadingStatus = () => (state: RootState) => state.rating.isLoading;
export const getRatingDataStatus = () => (state: RootState) => state.rating.dataRatingLoaded;
export const getLeaguesData = () => (state: RootState) => state.rating.leagues;
export const getLeaguesDataStatus = () => (state: RootState) => state.rating.dataLeaguesLoaded;
export const getRatingErrors = () => (state: RootState) => state.rating.error;

export default ratingReducer;
