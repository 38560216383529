import { CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import styles from './ConfirmPurchase.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import { useTranslation } from 'react-i18next';

type ConfirmPurchaseProps = {
  label: string,
  open: boolean,
  onClose: (active: boolean) => void,
  style?: CSSProperties
};

export default function ConfirmPurchase({ onClose, open, label }:ConfirmPurchaseProps ) {
  const {t} = useTranslation('ns1');
  return createPortal(
    <>
      <div className={`${styles.ConfirmPurchase} ${open ? styles.show : ""}`} >
        <div
          className={styles.Overlay}
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
          }}
        />
        <div className={styles.Body}>
          <div className={styles.Header}>
            <button
              className={styles.CloseBtn}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              { icons.timesIcon }
            </button>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Picture}>
              {icons.doneIcon}
            </div>
            <h1 className={styles.Title}>{t('Congratulation')}!</h1>
            <div className={styles.Text}>
              <span className={styles.Begin}>{t('You bought')} - </span>
              {label}
            </div>
          </div>
          <div className={styles.BottomWrap}>
            <MainButton
              heading='Done'
              onTouch={() => onClose(false)}
            />
          </div>
        </div>
      </div>
    </>,
    document.getElementById('confirm') as HTMLElement);
}
