import React, { useState } from 'react';
import styles from './SettingsPage.module.css';
import icons from '../../../utils/icons';
import getPathAndAlt from '../../../utils/pictures';
import { useHistory } from 'react-router-dom';
import Toggle from '../../common/Toggle';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { changeSettingsUser, getLanguageByLocale, getUserData, updateUserData } from '../../../store/user';
import {ROUTES} from "../../../routes";
import { useTranslation } from 'react-i18next';

export default function SettingsPage() {
  const tg: WebApp = window.Telegram.WebApp;
  const history = useHistory();
  const dispatch  = useAppDispatch();
  const {t} = useTranslation('ns1');
  const initialVibrationSetting  = useAppSelector(getUserData())?.user_setting.vibration;
  const locale  = useAppSelector(getUserData())?.user_setting.locale;
  const nameLanguage = useAppSelector(getLanguageByLocale(locale ?? "en"))?.title;
  const { src, alt } = getPathAndAlt("telegram");
  const [ isToggled, setIsToggled ] = useState(initialVibrationSetting ?? true);
  
  const handleChange = () => {
    if (!locale) return;
    setIsToggled(!isToggled);
    dispatch(changeSettingsUser({
      locale: locale,
      vibration: !isToggled,
      is_onboarding_complete: true,
    }));
    dispatch(updateUserData({
      user_setting: {
        locale: locale,
        vibration: !isToggled,
        is_onboarding_complete: true,
      }
    }))
  };

  const goToSupport = () => {
    const link = import.meta.env.VITE_SUPPORT_LINK
    tg.openTelegramLink(link);
  };

  const onClick = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>, href: string) => {
    e.stopPropagation();
    history.push(href);
  }

  return (
    <div className={styles.SettingsPage} >
      
        <div className={styles.Header}>
          <div className={styles.Title}>MMA Fighters</div>
          <button
            className={styles.CloseBtn}
            onClick={(e) => onClick(e, ROUTES.root)}
          >
            { icons.timesIcon }
          </button>
        </div>
        <div className={styles.Wrapper}>
          <div
            onClick={(e) => onClick(e, ROUTES.settings.lang)}
            className={`${styles.Setting} ${styles.top}`}
          >
            <div className={styles.Info}>
              <div className={styles.SettingTitle}>
                {t('Language')}
              </div>
            </div>
              <button
                className={styles.Btn}
              >
                <span className={styles.TextBtn}>
                  { t(`${nameLanguage}`) ?? t("English") }
                </span>
                <div className={styles.IconBtn}>
                  { icons.arrowIcon }
                </div>
              </button>
          </div>
          <div
            onClick={(e) => onClick(e, ROUTES.settings.guide)}
            className={styles.Setting}
          >
            <div className={styles.Info}>
              <div className={styles.SettingTitle}>
                {t('Instruction')}
              </div>
            </div>
              <button
                className={styles.Btn}>
                <span className={styles.TextBtn}></span>
                <div className={styles.IconBtn}>
                  { icons.arrowIcon }
                </div>
              </button>
          </div>
          <div
            onClick={(e) => onClick(e, ROUTES.settings.info)}
            className={styles.Setting}
          >
            <div className={styles.Info}>
              <div className={styles.SettingTitle}>
                {t('Airdrop info')}
              </div>
            </div>
              <button
                className={styles.Btn}
              >
                <span className={styles.TextBtn}></span>
                <div className={styles.IconBtn}>
                  { icons.arrowIcon }
                </div>
              </button>
          </div>
          <div
            onClick={goToSupport}
            className={`${styles.Setting} ${styles.bottom}`}
          >
            <div className={styles.Info}>
              <div className={styles.SettingTitle}>
                {t('Support')}
              </div>
              <div className={styles.SettingText}>
                {t('Join our telegram channel')}
              </div>
            </div>
              <button
                className={styles.Btn}>
                <div className={styles.ImageBtn}>
                  <img src={src} alt={alt} />
                </div>
              </button>
          </div>
          <div className={styles.Wrap}>
            <div className={`${styles.Setting} ${styles.all}`}>
              <div className={styles.Info}>
                <div className={styles.SettingTitle}>
                  {t('Vibration')}
                </div>
              </div>
              <button className={styles.Btn}>
                <Toggle
                  isToggled={isToggled}
                  onToggle={handleChange}
                />
              </button>
            </div>
            <div className={styles.Privacy}>
              {t('Privacy Policy')}
            </div>
          </div>
        </div>
    </div>
  )
}
