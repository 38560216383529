import React, { CSSProperties, useState } from 'react';
import styles from './TonConnectDailyCard.module.css';
import icons from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import {TonConnectDailyModal} from "../TonConnectDailyModal";
import {useTonWallet} from "@tonconnect/ui-react";

type TonConnectDailyCardProps = {
    title: string,
    style?: CSSProperties,
    disabled?: boolean,
    props?: React.HTMLAttributes<HTMLButtonElement>
};

export const TonConnectDailyCard: React.FC<TonConnectDailyCardProps> = ({ title, style, disabled, ...props }) => {
    const [show, setShow] = useState(false);
    const {t} = useTranslation('ns1');
    const wallet = useTonWallet();

    const isCompleted = wallet != null

    const handleOpenModal = () => {
        if (!isCompleted) {
            setShow(true);
        }
    };

    return (
        <>
            <button
                onClick={handleOpenModal}
                className={`${styles.TonConnectDailyCard} ${isCompleted ? styles.TonConnectDailyCardSuccess : ''}`}
                disabled={disabled}
                {...props}
            >
                <div className={styles.Wrap}>
                    <div className={styles.Title}>
                        {t(`${title}`)}
                    </div>
                    <div className={styles.Info}>
                        <div className={styles.Description}>
                            {
                                isCompleted ? t('TON Wallet connected') : t('Connect TON wallet')
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.Icon}>
                    {
                        isCompleted ? icons.doneIcon : <icons.tonConnectIcon />
                    }
                </div>
            </button>
            <TonConnectDailyModal
                open={show}
                onClose={() => setShow(false)}
            />
        </>
    )
}
