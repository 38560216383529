import React, { CSSProperties } from 'react';
import styles from './KnockdownButton.module.css';

const knockdown = 'https://assets.mmator.online/boosters/1.webp';

interface KnockdownButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string,
  onKnockdown: () => void,
  count: number,
  style?: CSSProperties
}

const KnockdownButton: React.FC<KnockdownButtonProps> = ({ id, onKnockdown, style, count, ...props }) => {
  return (
    <button
      className={styles.KnockdownButton}
      onClick={() => onKnockdown()}
      style={style}
      {...props}
    >
      <div className={styles.Image}>
        <img src={knockdown} alt="knockdown" />
      </div>
      {count ? (<i className={styles.Count}>{count}</i>) : null}
    </button>
  )
}

export default KnockdownButton;
