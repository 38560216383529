import { CSSProperties, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './ShopModal.module.css';
import icons from '../../../utils/icons';
import getPathAndAlt from '../../../utils/pictures';
import BoostCard from '../BoostCard';
import { Boost } from '../../../interfaces/boost.type';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {getShopBoostsData, getShopSilverPacksData, loadShop} from '../../../store/shop';
import ChangeCard from '../ChangeCard';
import { SilverPack } from '../../../interfaces/silverPack.type';
import { useTranslation } from 'react-i18next';

type ShopModalProps = {
  text: string,
  label: string,
  content: string,
  open: boolean,
  onClose: (active: boolean) => void,
  style?: CSSProperties
};

export default function ShopModal({ onClose, content, open, label, text }:ShopModalProps ) {
  const tg: WebApp = window.Telegram.WebApp;
  const {t} = useTranslation('ns1');
  const dispatch = useAppDispatch();
  const boostsList = useAppSelector(getShopBoostsData());
  const silverPacks = useAppSelector(getShopSilverPacksData())
  const { src, alt } = getPathAndAlt(content);
  
  useEffect(() => {
    tg.expand();
  }, []);

  useEffect(() => {
    if (open) {
      dispatch(loadShop());
    }
  }, [open])

  const displayListBoosts = (boosts: Boost[] | null) => {
    if (!boosts) return;
    return boosts.map( (boost) => {
      return (
        <BoostCard
          key={boost.id}
          boost={boost}
        />
      )
    } )
  };
  const displaySilverChanges = (items: SilverPack[] | null) => {
    if (items == null || !items.length) return;
    const options = items.map(pack => {
      return { id: pack.id, amount: pack.amount};
    });

    return (
      <ChangeCard
        options={options}
        items={items}
      />
    )
  };

  return createPortal(
    <>
      <div className={`${styles.ShopModal} ${open ? styles.show : ""}`} >
        <div
          className={styles.Overlay}
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
          }}
        />
        <div className={styles.Body}>
          <div className={styles.Header}>
            <button
              className={styles.CloseBtn}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              { icons.timesIcon }
            </button>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Picture}>
              <img
                className={styles.Image}
                src={src}
                alt={alt}
              />
            </div>
            {label && <h1 className={styles.Title}>{t(`${label}`)}</h1>}
            {text && <div className={styles.Text}>{t(`${text}`)}</div>}
          </div>
          <div className={styles.TopWrapper}>
            <h4>{t('Silver coins')}</h4>
            <ul className={styles.Purchase}>
              { displaySilverChanges(silverPacks) }
            </ul>
          </div>
          <div className={styles.SecondWrapper}>
            <h4>{t('Boosters')}</h4>
            {
              boostsList.length ? (
                <ul className={styles.List}>
                  { displayListBoosts(boostsList) }
                </ul>
              ) : ''
            }
          </div>
        </div>
      </div>
    </>,
    document.getElementById('popup') as HTMLElement);
}
