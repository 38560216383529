import { useParams } from 'react-router-dom';
import SettingsPage from '../pages/SettingsPage';
import InfoPage from '../pages/InfoPage';
import InstructionPage from '../pages/InstructionPage';
import LanguagePage from '../pages/LanguagePage';

export default function SettingLayout() {
  const { page } = useParams<{page?: string}>();
  
  return (
    <>
      {(!page) &&  <SettingsPage />}
      {(page === "info") &&  <InfoPage />}
      {(page === "guide") &&  <InstructionPage />}
      {(page === "lang") &&  <LanguagePage />}
    </>
  )
}
