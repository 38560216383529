import {CSSProperties, FC} from 'react';
import { createPortal } from 'react-dom';
import styles from './TonTransactionDailyModal.module.css';
import icons from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import {SecondButton} from "../SecondButton";
import {useAppSelector} from "../../../hooks/hooks.ts";
import {getDailyTransactionState} from "../../../store/tasks.ts";
import MainButton from "../MainButton";
import {useSendDailyTonTransaction} from "../../../hooks";

type TonTransactionDailyModalProps = {
    open: boolean,
    silver?: number,
    gold?: number,
    onClose: (active: boolean) => void,
    style?: CSSProperties
};

export const TonTransactionDailyModal: FC<TonTransactionDailyModalProps> = ({ onClose, open, silver, gold }) => {
    const {t} = useTranslation('ns1');
    const state = useAppSelector(getDailyTransactionState());
    const {onSendTransaction, isLoading} = useSendDailyTonTransaction()

    const isCompleted = state === 'completed';


    return createPortal(
        <>
            <div className={`${styles.TonTransactionDailyModal} ${open ? styles.show : ""}`} >
                <div
                    className={styles.Overlay}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose(false);
                    }}
                />
                <div className={styles.Body}>
                    <div className={styles.Header}>
                        <button
                            className={styles.CloseBtn}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClose(false);
                            }}
                        >
                            { icons.timesIcon }
                        </button>
                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Picture}>
                            <div className={styles.Light}></div>
                            {
                                isCompleted
                                    ? <icons.dailyTransactionSuccessIcon className={styles.Image} width={100} height={100}/>
                                    : <icons.dailyTransactionIcon className={styles.Image} width={100} height={100}/>
                            }
                        </div>
                        {<h1 className={styles.Title}>{t(`${isCompleted ? 'Congratulation' : 'Daily TON transaction'}`)}</h1>}
                        {<div className={styles.Text}>{t(`${isCompleted ? 'Your transaction was successful! The reward has already been awarded to you!' : 'Complete a transaction on the TON network and receive a reward'}`)}</div>}
                        <div className={styles.RewardWrapper}>
                            <span className={styles.RewardTitle}>{t('Reward')}</span>
                            <div className={styles.RewardList}>
                                {
                                    silver && (
                                        <div className={styles.RewardItem}>
                                            <div className={`${styles.RewardIcon}`}>
                                                {icons.silverIcon}
                                            </div>
                                            <div className={styles.Reward}>
                                                +{silver}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    gold && (
                                        <div className={styles.RewardItem}>
                                            <div className={`${styles.RewardIcon}`}>
                                                {icons.goldIcon}
                                            </div>
                                            <div className={styles.Reward}>
                                                +{gold}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.WrapperSecond}>
                        {
                            isCompleted ? (
                                <MainButton heading="Done" successBG={true} onTouch={() => onClose(false)}/>
                            ) : (
                                <SecondButton
                                    text={isLoading ? "Transaction Processing" : "Complete the task"}
                                    onClick={onSendTransaction}
                                    isLoading={isLoading}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </>,
        document.getElementById('daily') as HTMLElement);
}
