export const ROUTES = {
  root: '/',
  fight: {
    root: '/fight'
  },
  training: {
    root: '/training',
  },
  club: {
    root: '/club',
  },
  earn: {
    root: '/earn',
  },
  character: {
    root: '/character'
  },
  settings: {
    root: '/settings',
    guide: '/settings/guide',
    info: '/settings/info',
    lang: '/settings/lang',
  },
  onboarding: {
    root: '/onboarding',
  }
}