import { CSSProperties, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './DailyModal.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import getPathAndAlt from '../../../utils/pictures';
import DayCard from '../DayCard';
import { Reward } from '../../../interfaces/reward.type';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { checkDailyReward, getGameDailyRewardsData, getGameDailyRewardsLoading } from '../../../store/game';
import { useTranslation } from 'react-i18next';

type DailyModalProps = {
  id?: string,
  text: string,
  label: string,
  content: string,
  open: boolean,
  onClose: (active: boolean) => void,
  style?: CSSProperties
};

export default function DailyModal({ onClose, content, open, label, text }:DailyModalProps ) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('ns1');
  const rewardList = useAppSelector(getGameDailyRewardsData());
  const isLoading = useAppSelector(getGameDailyRewardsLoading());
  const [ isValid, setIsValid ] = useState(false);
  const { src, alt } = getPathAndAlt(content);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (validate(rewardList)) setIsValid(true);
    if (!validate(rewardList)) setIsValid(false);
  }, [rewardList, open]);
  
  const displayListDailyReward = (rewards: Reward[] | null) => {
    if (!rewards) return [];

    return rewards.map( (reward) => {
      return (
        <DayCard
          key={reward.day}
          day={reward.day}
          reward={reward.reward}
          isCompleted={reward.status === "rewarded"}
          isToday={reward.status === "available"}
        />
      )
    } )
  };

  const handleGetReward = () => {
    dispatch(checkDailyReward());
    /* HTML: <div class="loader"></div> */

   if (!isLoading) {
     onClose(false);
   }
  };

  const validate = (items: Reward[] | null) => {
    let isValid = false;
    if (!items) return;
    for (const item of items) {
      if (item.status === "available") {
        isValid = true;
      }
    }
    return isValid;
  };

  return createPortal(
    <>
      <div className={`${styles.DailyModal} ${open ? styles.show : ""}`} >
        <div
          className={styles.Overlay}
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
          }}
        />
        <div className={styles.Body}>
          <div className={styles.Header}>
            <button
              className={styles.CloseBtn}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              { icons.timesIcon }
            </button>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Picture}>
              <div className={styles.Light}></div>
              <img
                className={styles.Image}
                src={src}
                alt={alt}
              />
            </div>
            {label && <h1 className={styles.Title}>{t(`${label}`)}</h1>}
            {text && <div className={styles.Text}>{t(`${text}`)}</div>}
            {open && (
              <ul className={styles.List}>
                { displayListDailyReward(rewardList) }
              </ul>
            )}
          </div>
          <div className={styles.WrapperSecond}>
            <MainButton
              heading='get an reward'
              onTouch={handleGetReward}
              disabled={!isValid}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>,
    document.getElementById('daily') as HTMLElement);
}
