import {LeagueIdType} from "../interfaces/rating.type";

const colorByLeagueIdMapper: Record<LeagueIdType, string> = {
  "junior_3": '#4B4C4D',
  "junior_2": '#4A6473',
  "junior_1": '#509C69',
  "adult_3": '#44848F',
  "adult_2": '#378ACB',
  "adult_1": '#4541AF',
  "cms": '#7430AE',
  "ms": '#C6A04C',
  "msic": '#D87716',
  "professional": '#D0482B',
}

export function getLeagueColorById(id: LeagueIdType | undefined | null): string {
  if (id == null) {
    return colorByLeagueIdMapper.junior_3;
  }

  return colorByLeagueIdMapper[id] || colorByLeagueIdMapper.junior_3;
}