// Хранилище для запросов, ожидающих повторной отправки
let pendingRequests: any[] = [];

// Добавление запроса в очередь
export const addRequestToQueue = (callback: any) => {
  pendingRequests.push(callback);
};

// Выполнение всех накопленных запросов
export const processQueue = () => {
  pendingRequests.forEach((callback) => callback());
  clearQueue(); // Очищаем очередь после отправки
};

// Очистка очереди
export const clearQueue = () => {
  pendingRequests = [];
};