import { CSSProperties } from 'react';
import styles from './InfoPage.module.css';
import { useHistory } from 'react-router-dom';
import icons from '../../../utils/icons';
import { useTranslation } from 'react-i18next';

type InfoPageProps = {
  style?: CSSProperties
};

export default function InfoPage({  }:InfoPageProps ) {
  const history = useHistory();
  const {t} = useTranslation('ns1');
  
  return (
    <div className={styles.InfoPage} >
        <div className={styles.Header}>
          <button
            className={styles.Arrow}
            onClick={(e) => {
              e.stopPropagation();
              history.push("/settings");
            }}
          >
            { icons.arrowIcon }
          </button>
          <div className={styles.HeaderTitle}>{t('Airdrop info')}</div>
          <div className={styles.Empty}></div>
        </div>
        <div className={styles.Wrapper}>
          <div className={styles.Title}>
            {t('Information')}
          </div>
          <div className={styles.Text}>
            {t('The token listing')}
          </div>
        </div>
    </div>
  )
}
