import { Settings } from "../interfaces/user.type";
import httpService from "./http.service";

const tapEndpoint = "game/tap";
const sparringTapEndpoint = "game/sparring";
const sparringKnockdownEndpoint = "game/sparring/knockdown";
const dailyEndpoint = "daily-streak";
const getRewardDailyEndpoint = "daily-streak/checkin";
const settingsEndpoint = "user/settings";
const checkinEndpoint = "user/checkin";
const refferalsEndpoint = "user/referrals";
const shutdownEndpoint = "user/shutdown";
const tonConnectEndpoint = "user/wallet-connect";
const comboEndpoint = "daily-combo";
const checkComboEndpoint = "daily-combo/check";
const userInfo = 'user/info';

const gameService = {
  tap: async (payload: object) => {
    const { data } = await httpService.post(tapEndpoint, payload);
    return data;
  },
  sparringTap: async (payload: object) => {
    const { data } = await httpService.post(sparringTapEndpoint, payload);
    return data;
  },
  useKnockdown: async (payload: object) => {
    const { data } = await httpService.post(sparringKnockdownEndpoint, payload);
    return data;
  },
  fetchDaily: async () => {
    const { data } = await httpService.get(dailyEndpoint);
    return data;
  },
  fetchRefferals: async () => {
    const { data } = await httpService.get(refferalsEndpoint);
    return data;
  },
  getReward: async () => {
    const { data } = await httpService.post(getRewardDailyEndpoint);
    return data;
  },
  changeSettings: async (payload: Settings) => {
    const { data } = await httpService.post(settingsEndpoint, payload);
    return data;
  },
  checkIn: async () => {
    const { data } = await httpService.post(checkinEndpoint);
    return data;
  },
  shutdown: async () => {
    const { data } = await httpService.post(shutdownEndpoint);
    return data;
  },
  fetchCombo: async () => {
    const { data } = await httpService.get(comboEndpoint);
    return data;
  },
  checkCombo: async (payload: object) => {
    const { data } = await httpService.post(checkComboEndpoint, payload);
    return data;
  },
  userInfo: async () => {
    const { data } = await httpService.post(userInfo);
    return data;
  },
  walletConnect: async (payload: {source_address_user_friendly: string, source_address: string}) => {
    const { data } = await httpService.post(tonConnectEndpoint, payload);
    return data;
  }
};

export default gameService;
