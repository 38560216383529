import React, { CSSProperties, useState } from 'react';
import styles from './TonTransactionCard.module.css';
import icons from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import {TonTransactionModal} from "../TonTransactionModal";
import {useAppSelector} from "../../../hooks/hooks.ts";
import {getAirdropTransactionState} from "../../../store/user.ts";

type TonTransactionCardProps = {
    title: string,
    silver?: number,
    gold?: number,
    style?: CSSProperties,
    disabled?: boolean,
    props?: React.HTMLAttributes<HTMLButtonElement>
};

export const TonTransactionCard: React.FC<TonTransactionCardProps> = ({ title, silver, gold, style, disabled, ...props }) => {
    const [show, setShow] = useState(false);
    const state = useAppSelector(getAirdropTransactionState());
    const {t} = useTranslation('ns1');

    const isCompleted = state === 'completed';

    const handleOpenModal = () => {
        if (!isCompleted) {
            setShow(true);
        }
    };

    return (
        <>
            <button
                onClick={handleOpenModal}
                className={`${styles.TonTransactionCard} ${isCompleted ? styles.TonTransactionCardCompleted : ''}`}
                disabled={disabled}
                {...props}
            >
                <div className={styles.Wrap}>
                    <div className={styles.Title}>
                        {t(`${title}`)}
                    </div>
                    <div className={styles.Info}>
                        {
                            isCompleted ? (
                                <div className={styles.Description}>{t('You took your reward')}</div>
                            ) : (
                                <div className={styles.RewardContainer}>
                                    {
                                        silver && (
                                            <div className={styles.RewardItem}>
                                                <div className={`${styles.RewardIcon}`}>
                                                    {icons.silverIcon}
                                                </div>
                                                <div className={styles.Reward}>
                                                    +{silver}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        gold && (
                                            <div className={styles.RewardItem}>
                                                <div className={`${styles.RewardIcon}`}>
                                                    {icons.goldIcon}
                                                </div>
                                                <div className={styles.Reward}>
                                                    +{gold}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={styles.Icon}>
                    {
                        isCompleted ? icons.doneIcon : <icons.transactionIcon width={48} height={48}/>
                    }
                </div>
            </button>
            <TonTransactionModal
                silver={silver}
                gold={gold}
                open={show}
                onClose={() => setShow(false)}
            />
        </>
    )
}
