import { CSSProperties, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Leaderboard.module.css';
import icons from '../../../utils/icons';
import {getLeagueAssetsByLeagueId} from '../../../utils/pictures';
import Avatar from '../Avatar';
import {getLeagueColorById} from '../../../utils/colors';
import convertNumber from '../../../utils/numbers';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  getLeaguesData, getLeaguesDataStatus,
  getRatingData,
  getRatingUserData,
  loadDataLeagues,
  loadRatingLeague
} from '../../../store/rating';
import {IRating, LeagueIdType} from '../../../interfaces/rating.type';
import {getWidthProgressValue} from "../../../utils/helpers";
import { useTranslation } from 'react-i18next';
import {getUserData} from "../../../store/user";
import {IUser} from "../../../interfaces/user.type";
import {ILeague} from "../../../interfaces/league.type";

type LeaderboardProps = {
  text: string,
  label: string,
  content: string,
  open: boolean,
  onClose: (active: boolean) => void,
  style?: CSSProperties
};

export default function Leaderboard({ onClose, open, label }:LeaderboardProps ) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('ns1');
  const leagues = useAppSelector(getLeaguesData());
  const leaguesDataStatus = useAppSelector(getLeaguesDataStatus());
  const user = useAppSelector(getUserData()) as IUser;
  const ratingUser = useAppSelector(getRatingUserData());
  const currentRating = useAppSelector(getRatingData());
  const currentUserLeague = user.user_league;
  const [ order, setOrder ] = useState<number>(currentUserLeague.order);

  const currentLeague = leagues?.find(league => league.order === order);
  const [ opacity, setOpacity ] = useState<number>(1);
  const headerRef = useRef<HTMLDivElement | null>(null);

  const getLeagueAssetsByOrderPosition = (order: number | undefined) => {
    if (leagues == null || !leagues.length || order == null) {
      return getLeagueAssetsByLeagueId(currentUserLeague.id);
    }

    return getLeagueAssetsByLeagueId(leagues[order - 1].id as LeagueIdType);
  }

  useEffect(() => {
    if (headerRef && headerRef.current) {
      const headerHeight = headerRef.current.clientHeight;
      const range = 200;
      const offset = headerHeight / 2;
      
      const scrollPage = () => {
        let calc = 1 - (window.scrollY - offset + range) / range;
        
        if (calc > 1) {
          calc = 1;
        } else if (calc < 0) {
          calc = 0;
        }
      
      setOpacity(calc);
    };
    
    window.addEventListener("scroll", scrollPage);
    
    return () => {
      window.removeEventListener("scroll", scrollPage);
    };
    }
  }, [headerRef]);

  useEffect(() => {
    if (open) {
      dispatch(loadDataLeagues());
    }
  }, [open])

  useEffect(() => {
    if (leaguesDataStatus && open && order) {
      dispatch(loadRatingLeague((currentLeague as ILeague).id));
    }
  }, [leaguesDataStatus, open, order]);

  const displayLeaderboard = (players: IRating[] | null) => {
    if ((!players) || players.length === 0 ) return <div className={styles.Name}>There's no one here yet</div>;
    return players.map( (plr, index: number) => {
      return (
        <div key={plr.user.id} className={`${styles.Leader} ${index === 0 ? styles.first : ""} ${index === players.length - 1 ? styles.last : ""}`}>
          <div className={`${styles.Order} ${ratingUser?.user.id === plr.user.id ? styles.current : ""}`}>
            { plr.rank ?? "100+"}
          </div>
          <div className={styles.Avatar}>
            <Avatar photo={plr.user.photo_url} color={getLeagueColorById(plr.leagueId)} />
          </div>
          <div className={styles.ProgressInfo}>
            <div className={styles.Wrap}>
              <div className={styles.Name}>
                { plr.user.username }
              </div>
              <div className={styles.Values}>
                <div className={styles.ExpIcon}>
                  <icons.xpIcon />
                </div>
                {convertNumber(plr.user.earning_per_hour)}
              </div>
            </div>
          </div>
        </div>
      )
    } )
  };

  const handleIncreaseOrder = () => {    
    setOrder(prev => {
      if (!prev) return currentUserLeague?.order;
      if (!leagues?.length) return currentUserLeague?.order;
      if (prev === leagues.length) {
        return 1;
      }
      return prev + 1;
    })
  };

  const handleDecreaseOrder = () => {    
    setOrder(prev => {
      if (!prev) return currentUserLeague?.order;
      if (!leagues?.length) return currentUserLeague?.order;
      if (prev === 1) {
        return leagues.length;
      }
      return prev - 1;
    })
  };

  const isFirstLeague = order === 1
  const isLastLeague = leagues && order === leagues.length

  return createPortal(
    <>
      <div className={`${styles.Leaderboard} ${open ? styles.show : ""}`} >
        {
          open && (
            <>
              <div className={styles.Header} ref={headerRef} style={{ opacity: opacity }}>
                <div className={styles.Empty}></div>
                <div className={styles.Title}>
                  {t('Leaderboard')}
                </div>
                <button
                  className={styles.CloseBtn}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose(false);
                  }}
                >
                  { icons.timesIcon }
                </button>
              </div>
              <div className={styles.Body}>
                <div className={styles.Wrapper}>
                  {
                    <div
                      onTouchStart={handleDecreaseOrder}
                      className={`${styles.Arrow} ${isFirstLeague ? styles.Hidden : ''}`}
                    >
                      { icons.arrowIcon }
                    </div>
                  }
                  <div className={styles.Flag}>
                    <img
                      src={getLeagueAssetsByOrderPosition(order).flagUrl}
                      alt="league flag"
                    />
                  </div>
                  <div className={styles.Picture}>
                    <img
                      className={styles.Image}
                      src={getLeagueAssetsByOrderPosition(order).fighterUrl}
                      alt="league fighter"
                    />
                  </div>
                  {
                    <div
                      onTouchStart={handleIncreaseOrder}
                      className={`${styles.Arrow} ${styles.return} ${isLastLeague ? styles.Hidden : ''}`}>
                      { icons.arrowIcon }
                    </div>
                  }
                  <div className={styles.BlurBottom}></div>
                  <div className={styles.ShadowBottom}></div>
                </div>
                {label && <h1 className={styles.Title}>{currentLeague?.title}</h1>}
                <div className={styles.ProgressBar}>
                  {
                    ratingUser?.rank && (
                      <div className={`${styles.Order} ${styles.current}`}>
                        { ratingUser?.rank }
                      </div>
                    )
                  }
                  <div className={styles.Avatar}>
                    <Avatar photo={ratingUser?.user.photo_url} color={getLeagueColorById(currentLeague?.id)} />
                  </div>
                  <div className={styles.ProgressInfo}>
                    <div className={styles.Wrap}>
                      <div className={styles.Name}>
                        { ratingUser?.user.username }
                      </div>
                      <div className={styles.Values}>
                        <div className={styles.ExpIcon}>
                          <icons.xpIcon />
                        </div>
                        {
                          ratingUser?.rank
                            ? `${convertNumber(ratingUser?.user.earning_per_hour)} / ${convertNumber(currentLeague?.rules.max_score)}`
                            : `от ${convertNumber(currentLeague?.rules.min_score)}`
                        }
                      </div>
                    </div>
                    {
                      ratingUser?.rank && (
                        <div className={styles.Progress}>
                          <div className={styles.ProgressValue} style={{ width: `${getWidthProgressValue(ratingUser?.user.earning_per_hour, currentUserLeague?.rules.min_score, currentUserLeague?.rules.max_score)}%` }}></div>
                        </div>
                      )
                    }
                  </div>
                </div>
                <ul className={styles.List}>
                  { displayLeaderboard(currentRating) }
                </ul>
              </div>
              <div className={styles.Shadow}></div>
            </>
          )
        }
      </div>
    </>,
    document.getElementById('popup') as HTMLElement);
}
