import {useEffect, useRef, useState} from 'react';
import styles from './MainPage.module.css';
import Navbar from '../../ui/Navbar';
import MainHeader from '../../ui/MainHeader';
import EventBar from '../../ui/EventBar';
import combo from "../../../assets/images/Daily-combo.webp";
import sparring from "../../../assets/images/Sparring.webp";
import tournament from "../../../assets/images/Tournament.webp";
import ExperienceCount from '../../ui/ExperienceCount';
import EnergyBar from '../../ui/EnergyBar';
import ProgressBar from '../../ui/ProgressBar';
import ShopButton from '../../ui/ShopButton';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {usedKnockdown} from '../../../store/game';
import {
  checkIn,
  getUserData,
  getUserFirstStartUp,
  getUserLastEnergyAmount, getUserRenewed,
  syncEnergyUser, updateUserData,
} from '../../../store/user';
import ComboBar from '../../ui/ComboBar';
import {useLocation} from "react-router-dom";
import {ActiveEventEnum} from "../../../interfaces/enums";
import {IUser} from "../../../interfaces/user.type";
import {PunchingBagSection} from "./sections";
import {DailyComboSection} from "./sections";
import {SparringSection} from "./sections";
import TonButton from "../../ui/TonButton/TonButton";

const BG = './assets/images/BG_light.webp';
const sparringBG = './assets/images/Ring.webp';
const comboBG = './assets/images/Octagon.webp';
const MAX_TAP_PER_SECOND = 100;

export default function MainPage() {
  const location = useLocation()
  const tg: WebApp = window.Telegram.WebApp;
  const dispatch = useAppDispatch();
  const initUser = useAppSelector(getUserData()) as IUser;
  const firstStartUp = useAppSelector(getUserFirstStartUp());
  const syncEnergyValue = useAppSelector(getUserLastEnergyAmount());
  const renewed = useAppSelector(getUserRenewed());
  const currentLeague = initUser.user_league;
  const [ activeEvent, setActiveEvent ] = useState<ActiveEventEnum>(location?.state?.actionEvent || '');
  const expRef = useRef<number>(initUser.experience);
  const [exp, setExp] = useState<number>(() => initUser.experience);
  const [ energy, setEnergy ] = useState<number>(renewed.tap_energy);
  const energyRef = useRef<number>(0);
  const maxEnergy = initUser.tap_energy_max;
  const puchingPowerTap = initUser.tap_strength;
  const sparringPowerTap = currentLeague.sparring.tap_strength
  const [ currentTapPerSecond, setCurrentTapPerSecond ] = useState<number>(0);
  const [ onTimer, setOnTimer ] = useState<boolean>(false);

  // Sparring
  const isSparringComplete = initUser.user_sparring.next_sparring_ts !== null && (new Date().getTime() / 1000 <= initUser.user_sparring.next_sparring_ts);
  const [ sparringComplete, setSparringComplete ] = useState<boolean>(isSparringComplete);
  const maxHP = currentLeague.sparring.tap_energy_max
  const [ currentHP, setCurrentHP ] = useState<number>(() => isSparringComplete
    ? 0
    : currentLeague.sparring.tap_energy_max - Number(initUser.sparring_energy));

  // Combo
  const [ comboComplete, setComboComplete ] = useState<boolean>(initUser.user_daily_combo.state === 'completed');
  const [ currentCombo, setCurrentCombo ] = useState<string[]>([]);

  useEffect(() => {
    if (firstStartUp) {
      setEnergy(initUser.tap_energy_max);
    } else {
      if (syncEnergyValue && Object.keys(syncEnergyValue).length > 0) {
        const diffrenceSeconds = Math.floor(Date.now() / 1000) - Number(syncEnergyValue?.time);
        const currentEnergy = diffrenceSeconds + Number(syncEnergyValue?.energy);

        setEnergy(prev => {
          if (initUser.tap_energy_max && currentEnergy > initUser.tap_energy_max) {
            return prev;
          } else {
            return currentEnergy;
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    tg.expand();
    return () => {
      dispatch(syncEnergyUser({
        time: Math.floor(Date.now() / 1000),
        energy: Number(energyRef.current)
      }));
    }
  }, []);

  useEffect(() => {
    energyRef.current = energy;
  }, [energy]);

  useEffect(() => {
    expRef.current = exp;
  }, [exp]);

  useEffect(() => {
    return () => {
      dispatch(updateUserData({
        experience: Number(expRef.current)
      }))
    }
  }, [])

  useEffect(() => {
    if (initUser) {
      const secondsInterval = setInterval(() => {
        setCurrentTapPerSecond(0);

        // значение восстановления энергии в секунду
        const delta = 1;

        setEnergy( prev => {
          if (Number(prev) < Number(maxEnergy) - delta ) {
            return Number(prev) + delta;
          }
          return Number(maxEnergy);
        });

        const expPerSecond = Number(initUser.earning_per_hour) / 3600;
        expRef.current = Number(expRef.current) + expPerSecond;
        setExp(expRef.current);
      }, 1000);
  
      return () => {
        clearInterval(secondsInterval);
      };
    }
  }, [initUser, maxEnergy, puchingPowerTap]);

  const handleSparringKnockdown = async () => {
    const usedEnergy = Number(currentLeague?.sparring.tap_strength) * Number(currentLeague?.sparring.knockdown_energy);
    await dispatch(usedKnockdown({
      timestamp: Math.floor(Date.now() / 1000),
      usedEnergy: usedEnergy
    }));
    if (Number(currentHP) - usedEnergy <= 0) {
      setSparringComplete(true);
      setCurrentHP(0);
      expRef.current = Number(expRef.current) +  Number(currentLeague?.sparring.reward_experience);
      dispatch(checkIn());
    } else {
      setCurrentHP(prev => Number(prev) - usedEnergy);
    }
  };

  return (
    <div className={styles.MainPage}>
      <div className={styles.Wrapper}>
        <MainHeader user={initUser}/>
      </div>
      <div
        className={styles.Image}
      >
        <img
          className={styles.BG}
          src={activeEvent === ''
            ? BG
            : activeEvent === 'Daily combo' ? comboBG : sparringBG}
          alt="jam background"
        />
        <div className={styles.BlurTop}></div>
        <div className={styles.WrapperTop}>
          <div className={styles.Events}>
            <EventBar
              name={ActiveEventEnum.DAILY_COMBO}
              title='Daily combo'
              timestamp={initUser.user_daily_combo.next_combo_ts}
              isCompleted={comboComplete}
              active={activeEvent === 'Daily combo'}
              onActive={setActiveEvent}
            >
              <img src={combo} alt="Daily combo" />
            </EventBar>
            <EventBar
              name={ActiveEventEnum.SPARRING}
              title='Sparring'
              timestamp={initUser.user_sparring.next_sparring_ts}
              isCompleted={sparringComplete}
              active={activeEvent === 'Sparring'}
              onActive={setActiveEvent}
            >
              <img src={sparring} alt="Sparring" />
            </EventBar>
            <EventBar
              name={ActiveEventEnum.TOURNAMENT}
              title='Tournament'
              timestamp={null}
              isCompleted={false}
              active={activeEvent === 'Tournament'}
              onActive={setActiveEvent}
              comingSoon={true}
            >
              <img src={tournament} alt="Tournament" />
            </EventBar>
          </div>
          <ExperienceCount
            count={Math.floor(Number(expRef.current))}
          />
        </div>
        {activeEvent === "" && (
          <PunchingBagSection
            energy={energy}
            setEnergy={setEnergy}
            setOnTimer={setOnTimer}
            currentTapPerSecond={currentTapPerSecond}
            setCurrentTapPerSecond={setCurrentTapPerSecond}
            powerTap={puchingPowerTap}
            maxTapPerSecond={MAX_TAP_PER_SECOND}
            maxEnergy={maxEnergy}
            onTimer={onTimer}
            exp={expRef}
          />
        )}
        {activeEvent === 'Daily combo' && (
          <DailyComboSection
            currentCombo={currentCombo}
            setCurrentCombo={setCurrentCombo}
            setComboComplete={setComboComplete}
            comboComplete={comboComplete}
          />
        )}
        {activeEvent === "Sparring" && (
          <SparringSection
            sparringComplete={sparringComplete}
            setSparringComplete={setSparringComplete}
            setOnTimer={setOnTimer}
            currentHP={currentHP}
            currentLeague={currentLeague}
            currentTapPerSecond={currentTapPerSecond}
            setCurrentTapPerSecond={setCurrentTapPerSecond}
            powerTap={sparringPowerTap}
            maxTapPerSecond={MAX_TAP_PER_SECOND}
            maxHP={maxHP}
            setCurrentHP={setCurrentHP}
            exp={expRef}
            onTimer={onTimer}
          />
        )}
        <div className={styles.BlurBottom}></div>
      </div>
      <TonButton />
      <ShopButton />
      <div className={styles.WrapperBottom}>
        {activeEvent === "" && <EnergyBar
            current={energy}
            total={maxEnergy}
            style={{ marginBottom: '16px' }}
            onKnockdown={handleSparringKnockdown}
        />}
        {activeEvent === "Sparring" && <EnergyBar
            current={currentHP}
            total={maxHP}
            style={{ marginBottom: '16px' }}
            knockdown={true}
            onKnockdown={handleSparringKnockdown}
            sparringIsCompleted={sparringComplete}
        />}
        {activeEvent === "Daily combo" && !comboComplete && <ComboBar
            combo={currentCombo}
            style={{ marginBottom: '16px' }}
            comboIsCompleted={comboComplete}
        />}
        <ProgressBar
          league={currentLeague}
          exp_per_hour={initUser?.earning_per_hour}
          current={currentLeague?.order}
          total={currentLeague.total_leagues}
          username={initUser?.username}
          photo={initUser?.photo_url}
        />
      </div>
      <Navbar onActive={setActiveEvent} />
    </div>
  )
}
