import { CSSProperties, useState } from 'react';
import styles from './BoostCard.module.css';
import getPathAndAlt from '../../../utils/pictures';
import icons from '../../../utils/icons';
import convertNumber from '../../../utils/numbers';
import { Boost } from '../../../interfaces/boost.type';
import BoostModal from '../BoostModal';

type BoostCardProps =  {
  boost: Boost,
  style?: CSSProperties
};

export default function BoostCard({ boost, style }:BoostCardProps) {
  const { name, image, next_level, price_silver, max_in_inventory, bg_color, max_level } = boost;
  const { src: src2, alt: alt2 } = getPathAndAlt("XTR");
  const [show, setShow] = useState(false);
  let stl = {
    ...style,
    background: bg_color
  };

  return (
    <>
      <div
        onClick={() => setShow(true)}
        className={styles.BoostCard}
        style={stl}
      >
        <div className={styles.Icon}>
          <img src={image} alt={name} />
        </div>
        <div className={styles.Info}>
          <div className={styles.Title}>
            { name }
          </div>
          <div className={styles.Text}>
            {max_in_inventory === 1000000000 ? "10/50/80/160/320" : max_level ? `Level ${next_level ?? 1}` : ''}
          </div>
          <div className={styles.PriceBox}>
            <div className={styles.EarnIcon}>
              { max_in_inventory === 1000000000 ? <img src={src2} alt={alt2} /> : icons.silverIcon }
            </div>
            <div className={styles.Price}>
              { max_in_inventory === 1000000000 ? "10" : convertNumber(price_silver) }
            </div>
          </div>
        </div>
      </div>
      <BoostModal
        boost={boost}
        open={show}
        onClose={()=>setShow(false)}
      />
    </>
  )
}
