import httpService from "./http.service";

const signInEndpoint = "user/auth";

const authService = {
  register: async (payload: object) => {
    const { data } = await httpService.post(signInEndpoint, payload);
    return data;
  },
};

export default authService;
