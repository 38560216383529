import { useEffect, useState } from 'react';
import styles from './TrainingPage.module.css';
import SwitchPanel from '../../ui/SwitchPanel';
import Navbar from '../../ui/Navbar';
import { SwitchOption } from '../../../interfaces/switchOption.type';
import TrainingCard from '../../ui/TrainingCard';
import { Card } from '../../../interfaces/card.type';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {getCardsData, getCardsLoadingStatus, loadCards} from '../../../store/cards';
import { useTranslation } from 'react-i18next';

export default function TrainingPage() {
  const dispatch = useAppDispatch();
  const tg: WebApp = window.Telegram.WebApp;
  const {t} = useTranslation('ns1');
  const cards = useAppSelector(getCardsData());
  const cardsLoadingStatus = useAppSelector(getCardsLoadingStatus());
  const cardsOptions: SwitchOption[] = cards && Object.values(cards).length
    ? tranformObjtoSwitchOptions(cards)
    : [];
  const [ active, setActive ] = useState(() => cardsOptions[0]);

  useEffect(() => {
    if (!cardsLoadingStatus && active == null) {
      setActive(cardsOptions[0]);
    }
  }, [cardsLoadingStatus])

  useEffect(() => {
    if (active) tg.expand();
  }, [active]);

  useEffect(() => {
    const scrollPage = () => {
      tg.expand();
    };
    window.addEventListener("scroll", scrollPage);
  
    return () => {
      window.removeEventListener("scroll", scrollPage);
    };
  }, []);

  useEffect(() => {
    dispatch(loadCards())
  }, [dispatch]);

  function tranformObjtoSwitchOptions(cards: object) {
    return Object.keys(cards).map((optionName, index) => {
      return {
        id: String(index + 1),
        title: optionName
      };
    });
  }

  const renderCards = (items: Card[]) => {
    if (!items) return;
    return items.map((item: Card) => (
      <li key={item.id}>
        <TrainingCard
          card={item}
          disabled={!item.next_level.available}
        />
      </li>
    ))
  };

  return (
    <div className={styles.TrainingPage}>
      <div className={styles.WrapHeader}>
        <h2>{t('Training')}</h2>
        <SwitchPanel
          heading=''
          onSwitch={setActive}
          options={cardsOptions}
          active={active}
          fontSize={13}
          style={{ marginBottom: "24px" }}
          />
      </div>
      <ul className={styles.List}>
        { cardsLoadingStatus || !cards ? "Loading..." : renderCards(cards[active?.title]) }
      </ul>
      <Navbar />
    </div>
  )
}
