import { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import './App.css'
import CharacterPage from './components/pages/CharacterPage'
import MainPage from './components/pages/MainPage'
import EarnPage from './components/pages/EarnPage'
import TrainingPage from './components/pages/TrainingPage'
import FightPage from './components/pages/FightPage'
import ClubPage from './components/pages/ClubPage'
import AppLoader from './components/hoc/appLoader'
import SettingLayout from './components/layouts/SettingLayout'
import InstructionPage from "./components/pages/InstructionPage";
import {ROUTES} from "./routes";
import '../i18n';
import * as Sentry from "@sentry/react";
import {apiEndpoint} from "./constants";
import history from "./utils/history";
import {TonConnectUIProvider} from "@tonconnect/ui-react";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    // @ts-ignore (Ругался на history, хоть сделано по документации)
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [apiEndpoint, 'https://front.mmator.online'],
  profilesSampleRate: 0.1,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const SentryRoute = Sentry.withSentryRouting(Route);

function App() {
  const tg: WebApp = window.Telegram.WebApp;
  // const dispatch = useAppDispatch();
  useEffect(() => {
    let touchStartEvent: any;
    const touchstart = (e: any) => {
      console.log("start",e);
      touchStartEvent = e;
      console.log("touchStartEvent in start",touchStartEvent);
    };
    const touchend = (e: any) => {
      console.log("end",e);

      if ((e.originalEvent.pageY > touchStartEvent.originalEvent.pageY && e.target.scrollTop == 0) ||
            (e.originalEvent.pageY < touchStartEvent.originalEvent.pageY && e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight))
            e.preventDefault();
    };
    document.body.addEventListener("scroll", touchstart);
    document.body.addEventListener("scroll", touchend);

    // return () => {
    //   document.body.removeEventListener("scroll", scrollPage);
    // };
  }, []);
  
  useEffect(() => {

    tg.ready();
    tg.expand();
    tg.setHeaderColor("#21282E");
    tg.setBackgroundColor("#21282E");
    tg.disableVerticalSwipes();
    tg.enableClosingConfirmation();
  },[]);

  function reexpandWindow() {
    // @ts-ignore
    if (window.isWAexpand) {
      return;
    }
    // @ts-ignore
    window.isWAexpand = true;
    setTimeout(() => {
      tg.expand();
      // @ts-ignore
      window.isWAexpand = false;
    })
  }
  useEffect(() => {
    tg.onEvent("viewportChanged", reexpandWindow);
    // @ts-ignore
  }, [window.isWAexpand]);

  return (
    <div className='App'>
      <TonConnectUIProvider manifestUrl={`${import.meta.env.VITE_APP_BASE_URL}/tonconnect-manifest.json`}>
        <AppLoader>
          <Switch>
            <SentryRoute  exact path={ROUTES.fight.root} component={FightPage}/>
            <SentryRoute exact path={ROUTES.training.root} component={TrainingPage}/>
            <SentryRoute exact path={ROUTES.club.root} component={ClubPage}/>
            <SentryRoute exact path={ROUTES.earn.root} component={EarnPage}/>
            <SentryRoute exact path={ROUTES.character.root} component={CharacterPage}/>
            <SentryRoute exact path={`${ROUTES.settings.root}/:page?`} component={SettingLayout}/>
            <SentryRoute exact path={ROUTES.root} component={MainPage}/>
            <SentryRoute exact path={ROUTES.onboarding.root} component={InstructionPage}/>
          </Switch>
        </AppLoader>
      </TonConnectUIProvider>
    </div>
  )
}

export default App
