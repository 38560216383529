import ComboContent from "../../../../ui/ComboContent";
import styles from "../../MainPage.module.css";
import React, {FC, useEffect, useRef} from "react";
import getNamePunchById from "../../../../../utils/punches";
import {SpinePlayer} from "@esotericsoftware/spine-player";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {checkDailyCombo, getGameComboData, loadDailyCombo} from "../../../../../store/game";
import {decode} from "base-64";

const comboAtlasUrl = './assets/animation/Combo/Dailik.atlas';
const comboJsonUrl = './assets/animation/Combo/Dailik.json';

type Props = {
  currentCombo: string[];
  setCurrentCombo: React.Dispatch<React.SetStateAction<string[]>>;
  comboComplete: boolean;
  setComboComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DailyComboSection: FC<Props> = ({
  currentCombo,
  setCurrentCombo,
  setComboComplete,
  comboComplete
}) => {
  const dispatch = useAppDispatch();
  const comboPlayer = useRef<SpinePlayer | null>(null);
  const comboPlayerIsReady = useRef<boolean>(false);

  const comboData = useAppSelector(getGameComboData());
  const decode_combo = decode(comboData || '');
  const array_combo = decode_combo.split("");

  useEffect(() => {
    dispatch(loadDailyCombo());

    if (comboComplete) {
      return;
    }

    comboPlayer.current = new SpinePlayer("combo", {
      jsonUrl: comboJsonUrl,
      atlasUrl: comboAtlasUrl,
      premultipliedAlpha: true,
      preserveDrawingBuffer: false,
      showControls: false,
      defaultMix: 0,
      showLoading: false,
      animations: ["Idle", "Hit_Head"],
      animation: "Idle",
      // skins: ["Red", "Blue"],
      // skin: "Test_01",
      alpha: true,
      backgroundColor: "#00000000",
      viewport: {
        x: 0,
        y: 0,
        padRight: 0,
        padTop: 450,
        padBottom: 250,
        padLeft: 200
      },
      scale: 5,
      success: () => {
        comboPlayerIsReady.current = true;
      }
    });
  }, [])

  const handleComboTap = (id: string) => {
    if (!comboPlayerIsReady.current) {
      return;
    }

    comboPlayer.current?.setAnimation(getNamePunchById(id), false);
    if (id === array_combo[currentCombo.length]) {
      if (currentCombo.length === 4) {
        setCurrentCombo(prev => [...prev, id]);

        setComboComplete(true);
        dispatch(checkDailyCombo({
          combo: [...currentCombo, id].join("")
        }));
      } else {
        setCurrentCombo(prev => [...prev, id]);
      }
    } else {
      setCurrentCombo([]);
    }
  };

  return (
    <>
      {
        !comboComplete && (
          <>
            <ComboContent onHex={handleComboTap} />
            <div
              className={styles.Player}
              id="combo"
              style={{ width: '100%' }}
            ></div>
          </>
        )
      }
    </>
  )
}