import React, { CSSProperties } from 'react';
import styles from './InviteCard.module.css';
import getPathAndAlt from '../../../utils/pictures';
import icons from '../../../utils/icons';
import convertNumber from '../../../utils/numbers';
import { useTranslation } from 'react-i18next';

interface InviteCardProps {
  id?: string,
  title: string,
  type: string,
  reward_amount: number,
  reward_currency: string,
  description: string,
  style?: CSSProperties,
  props?: React.HTMLAttributes<HTMLDivElement>
}

export default function InviteCard({ title, type, reward_amount, reward_currency, description, style, ...props }:InviteCardProps) {
  const { src, alt } = getPathAndAlt(type);
  const {t} = useTranslation('ns1');
  const leftColor = '#2D3339';

  function switchColorBG(type:string | undefined) {
    switch (type) {
      case "gift":
        return "#F04A6880";
      case "star":
        return "#A061BD80";
      default:
        return "#6B859F80";
    }
  }

  const stl = {
    ...style,
    background: `linear-gradient(90deg, ${leftColor} 0%, ${leftColor} 50%, ${switchColorBG(type)} 100%)`
  };
  
  return (
    <>
      <div
        className={styles.InviteCard}
        style={stl}
        {...props}
      >
        <div className={styles.Wrap}>
          <div className={styles.Title}>
            {t(`${title}`)}
          </div>
          <div className={styles.Info}>
            <div className={`${styles.RewardIcon} ${reward_currency === 'silver' ? styles.silver : ""}`}>
              { icons.silverIcon }
            </div>
            <div className={styles.Reward}>
              +{convertNumber(reward_amount)}
            </div>
            <div className={`${styles.Description} ${((type === 'star') || (type === 'gift') )? styles.another : ""}`}>
              {t(`${description}`)}
            </div>
          </div>
        </div>
        <div className={styles.Icon}>
          {
            type === 'star' ? <icons.starIcon /> : <img src={src} alt={alt} />
          }
        </div>
      </div>
    </>
  )
}
