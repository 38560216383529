import httpService from "./http.service";

const shopEndpoint = "shop";
const buySilverEndpoint = "shop/coin/buy";
const buyBoosterEndpoint = "shop/booster/buy";
const updateBoosterEndpoint = "shop/booster/upgrade";

const shopService = {
  fetchAll: async () => {
    const { data } = await httpService.get(shopEndpoint);
    return data;
  },
  buySilver: async (payload: object) => {
    const { data } = await httpService.post(buySilverEndpoint, payload);
    return data;
  },
  buyBooster: async (payload: object) => {
    const { data } = await httpService.post(buyBoosterEndpoint, payload);
    return data;
  },
  updateBooster: async (payload: object) => {
    const { data } = await httpService.post(updateBoosterEndpoint, payload);
    return data;
  },
};

export default shopService;
