import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./createStore";
import taskService from "../services/task.service";
import { Task } from "../interfaces/task.type";
import {updateUserData} from "./user";
import {DailyTransaction} from "../interfaces/dailyTransaction.type.ts";

type SliceState = {
  entities: null | Task[],
  lastCheckTask: any,
  isLoading: boolean,
  error: any,
  dataLoaded: boolean,
  dailyTransaction: {
    data: DailyTransaction | null,
    isLoading: boolean,
    error: any,
  }
}
const initialState: SliceState = {
  entities: [],
  isLoading: false,
  lastCheckTask: null,
  error: null,
  dataLoaded: false,
  dailyTransaction: {
    data: null,
    isLoading: false,
    error: null,
  }
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    tasksRequested: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    tasksRequestSuccess: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
      state.dataLoaded = true;
    },
    tasksRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    checkTaskRequested: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    checkTaskRequestSuccess: (state, action) => {
      state.lastCheckTask = action.payload;
      state.isLoading = false;
    },
    checkTaskRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateTaskStatus: (state, action) => {
      state.entities = state.entities && state.entities.map(item => {
        if (action.payload.id && action.payload.status && item.id === action.payload.id) {
          return {
            ...item,
            user_task_status: action.payload.status
          }
        }
        return item;
      })
    },
    dailyTransactionRequested: (state) => {
      state.dailyTransaction.isLoading = true;
      state.dailyTransaction.error = null;
    },
    dailyTransactionRequestSuccess: (state, action) => {
      state.dailyTransaction.data = action.payload;
      state.dailyTransaction.isLoading = false;
    },
    dailyTransactionRequestFailed: (state, action) => {
      state.dailyTransaction.isLoading = false;
      state.dailyTransaction.error = action.payload;
    },
    updateDailyTransactionState: (state, action) => {
      if (state?.dailyTransaction?.data?.state) {
        state.dailyTransaction.data.state = action.payload;
      }
    }
  }
});

const { actions, reducer: tasksReducer } = tasksSlice;
const {
  tasksRequested,
  tasksRequestSuccess,
  tasksRequestFailed,
  checkTaskRequested,
  checkTaskRequestSuccess,
  checkTaskRequestFailed,
  updateTaskStatus,
  dailyTransactionRequestFailed,
  dailyTransactionRequestSuccess,
  dailyTransactionRequested,
  updateDailyTransactionState,
} = actions;

export {updateDailyTransactionState};

export const loadTasks = () => async (dispatch: any) => {
  dispatch(tasksRequested());
  try {
    const { content } = await taskService.fetchAll();
    dispatch(tasksRequestSuccess(content.tasks));
  } catch (error: any) {
      dispatch(tasksRequestFailed(error.message));
  }
};
export const checkedTask = (payload: string) => async (dispatch: any) => {
  dispatch(checkTaskRequested());
  try {
    const { content } = await taskService.check(payload);
    dispatch(checkTaskRequestSuccess(content));
    dispatch(updateTaskStatus({status: content.user_task_status, id: content.task.id}))
    dispatch(updateUserData(content.user))
  } catch (error: any) {
    dispatch(checkTaskRequestFailed(error.message));
  }
};

export const loadTransactionDaily = () => async (dispatch: any) => {
  dispatch(dailyTransactionRequested());
  try {
    const { content } = await taskService.checkDailyTransaction();
    dispatch(dailyTransactionRequestSuccess(content as DailyTransaction));
  } catch (error: any) {
    dispatch(dailyTransactionRequestFailed(error.message));
  }
}

export const getTasksLoadingStatus = () => (state: RootState) => state.tasks.isLoading;
export const getTasksDataStatus = () => (state: RootState) => state.tasks.dataLoaded;
export const getTasksData = () => (state: RootState) => state.tasks.entities;
export const getTasksErrors = () => (state: RootState) => state.tasks.error;
export const getDailyTransaction = () => (state: RootState) => state.tasks.dailyTransaction
export const getDailyTransactionState = () => (state: RootState) => state.tasks.dailyTransaction?.data?.state

export default tasksReducer;
