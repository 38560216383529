import { CSSProperties } from 'react';
import styles from './MainHeader.module.css';
import icons from '../../../utils/icons';
import XPsvg from "../../../assets/icons/XP.svg?react";
import { IUser } from '../../../interfaces/user.type';
import convertNumber from '../../../utils/numbers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type MainHeaderProps = {
  user: IUser | null,
  style?: CSSProperties
};

export default function MainHeader({ user, style }:MainHeaderProps) {
  if (!user) {
    return "Loading...";
  } else {
    const history = useHistory();
    const {t} = useTranslation('ns1');
    const { user_wallet, earning_per_hour } = user;
    const { silver, gold } = user_wallet;
    
    return (
      <div className={styles.MainHeader} style={style}>
        <div className={styles.Profit}>
          <div className={styles.IconXP}>
            <XPsvg />
          </div>
          <div className={styles.Info}>
            <div className={styles.Title}>
              {t('Hourly earnings')}
            </div>
            <div className={styles.Wrap}>
              <div className={styles.Amount}>
                +{ convertNumber(earning_per_hour) ?? 0 }
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Wrapper}>
          <div className={styles.Coins}>
            <div className={styles.Silver}>
              <div className={styles.CoinIcon}>
                {icons.silverIcon}
              </div>
              <div className={styles.Amount}>
                {  silver ? convertNumber(silver) : 0 }
              </div>
            </div>
            <div className={styles.Gold}>
              <div className={styles.CoinIcon}>
                {icons.goldIcon}
              </div>
              <div className={styles.Amount}>
              {  gold ? convertNumber(gold) : 0 }
              </div>
            </div>
          </div>
          <div className={styles.Settings} onClick={() => history.push("/settings")}>
            <div className={styles.SettingsIcon}>
              {icons.settingsIcon}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
