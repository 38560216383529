import { CSSProperties } from 'react';
import styles from './MainEventCard.module.css';
import icons from '../../../utils/icons';
import GobletSvg from "../../../assets/icons/Goblet.svg?react";
import RankSvg from "../../../assets/icons/Rank.svg?react";
import tournament from "../../../assets/images/Tournament.webp";
import { useTranslation } from 'react-i18next';

type MainEventCardProps = {
  title: string,
  rank: number,
  disabled?: boolean
  style?: CSSProperties
};

const format = (vals: number[]) =>
  vals.map((v) => v.toString().padStart(2, '0')).join(':');

export default function MainEventCard({ title, rank, style, disabled = false }:MainEventCardProps) {
  const tg: WebApp = window.Telegram.WebApp;
  const {t} = useTranslation('ns1');
  
  return (
    <>
      <button
        onClick={() => disabled ? undefined : tg.showAlert(tg?.initData)}
        className={styles.MainEventCard}
        style={style}
      >
        {
          disabled && (
            <>
              <div className={styles.Blur}></div>
              <div className={styles.Lock}>
                <icons.lockIcon color="#fff" />
              </div>
            </>
          )
        }
        <div className={styles.Icon}>
          <img src={tournament} alt={`icon ${title}`}/>
        </div>
        <div className={styles.Info}>
          <div className={styles.Header}>
            <div className={styles.Title}>
              {t('Tournament')}
            </div>
            <div className={styles.Time}>
              <div className={styles.TimerIcon}>
                {icons.timerIcon}
              </div>
              <div className={styles.Timer}>
                { format([1, 20 , 44]) }
              </div>
            </div>
          </div>
          <div className={styles.Footer}>
            <div className={styles.Wrap}>
              <div className={styles.InfoIcon}>
                <RankSvg />
              </div>
              <div className={styles.Box}>
                <div className={styles.HeaderBox}>
                  {t('Rating')}
                </div>
                <div className={styles.FooterBox}>
                  {rank}/100
                </div>
              </div>
            </div>
            <div className={styles.Wrap}>
              <div className={styles.InfoIcon}>
                <GobletSvg />
              </div>
              <div className={styles.Box}>
                <div className={styles.HeaderBox}>
                  {t('Victories')}
                </div>
                <div className={styles.FooterBox}>
                  10/10
                </div>
              </div>
            </div>
          </div>
        </div>
      </button>
    </>
  )
}
