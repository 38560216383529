import {CSSProperties, useEffect, useRef, useState} from 'react';
import styles from './FightEventCard.module.css';
import icons from '../../../utils/icons';
import {FightWithActiveEvent} from '../../../interfaces/fight.type';
import getPathAndAlt from '../../../utils/pictures';
import {useHistory} from "react-router-dom";
import {ROUTES} from "../../../routes";
import {useAppSelector} from "../../../hooks/hooks";
import {getUserData} from "../../../store/user";
import { useTranslation } from 'react-i18next';

interface FightEventCardProps extends FightWithActiveEvent {
  style?: CSSProperties
}

const format = (vals: number[]) =>
  vals.map((v) => v.toString().padStart(2, '0')).join(':');

export default function FightEventCard({ title, reward, context, activeEvent, leftColor = '#2D3339', rewardType, style }:FightEventCardProps) {
  const history = useHistory();
  const { src, alt } = getPathAndAlt(context);
  const userData = useAppSelector(getUserData());
  const {t} = useTranslation('ns1');

  const calculateTime = (context: string): null | number => {
    if (context === 'combo') {
      const nextComboTs = userData?.user_daily_combo?.next_combo_ts;

      if (nextComboTs != null) {
        const currentDateSeconds = Math.floor(new Date().getTime() / 1000);
        const changedTime = nextComboTs - currentDateSeconds;

        if (changedTime < 0) {
          return null;
        } else {
          return changedTime;
        }
      }
    }

    if (context === 'sparring') {
      const nextSparringTs = userData?.user_sparring?.next_sparring_ts;

      if (nextSparringTs != null) {
        const currentDateSeconds = Math.floor(new Date().getTime() / 1000);
        const changedTime = nextSparringTs - currentDateSeconds;

        if (changedTime < 0) {
          return null
        } else {
          return changedTime;
        }
      }
    }

    return null;
  }

  const [time, setTime] = useState<number | null>(() => calculateTime(context))

  function getFormatTime(secondsDuration: number) {
    const hours = Math.floor(secondsDuration / 3600);
    const minutes = Math.floor(secondsDuration % 3600 / 60);
    const seconds = Math.floor(secondsDuration % 3600 % 60);

    return [hours, minutes, seconds]
  }

  const intervalId = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (context === 'combo' && userData?.user_daily_combo?.state === 'completed') {
      intervalId.current = setInterval(() => {
        setTime(calculateTime(context));
      }, 1000);
    }

    if (context === 'sparring') {
      intervalId.current = setInterval(() => {
        setTime(calculateTime(context));
      }, 1000)
    }

    return () => {
      if (intervalId.current != null) {
        clearInterval(intervalId.current)
      }
    }
  }, [context, userData])

  function switchColorBG(type:string | undefined) {
    switch (type) {
      case "combo":
        return "#799F6B80";
      case "sparring":
        return "#9F786B80";
      default:
        return "#6B859F80";
    }
  }

  const stl = {
    ...style,
    background: time ? 'rgba(98, 135, 80, 0.3)' : `linear-gradient(90deg, ${leftColor} 0%, ${leftColor} 50%, ${switchColorBG(context)} 100%)`
  };

  const onClick = () => {
    history.push({
      pathname: ROUTES.root,
      state: {
        actionEvent: activeEvent
      }
    });
  };
  
  return (
    <>
      <button
        onClick={onClick}
        className={styles.FightEventCard}
        style={stl}
      >
        <div className={styles.Wrap}>
          <div className={styles.Title}>
            {t(`${title}`)}
          </div>
          <div className={styles.Info}>
            {reward && <div className={`${styles.RewardIcon} ${styles.silver}`}>
              {rewardType === 'silver' ? icons.silverIcon : <icons.xpIcon /> }
            </div>}
            {reward && <div className={styles.Reward}>
              +{reward}
            </div>}
            {reward && <div className={styles.Separator}></div>}
            <div className={styles.Time}>
              <div className={styles.TimerIcon}>
                {icons.timerIcon}
              </div>
              <div className={styles.Timer}>
                { time ? format(getFormatTime(time)) : '' }
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Icon}>
          <img src={src} alt={alt} />
        </div>
      </button>
    </>
  )
}
