import { CSSProperties } from 'react';
import styles from './TableRowFriends.module.css';
import icons from '../../../utils/icons';
import Avatar from '../Avatar';
import {getLeagueColorById} from '../../../utils/colors';
import convertNumber from '../../../utils/numbers';
import { IRefferal } from '../../../interfaces/refferal.type';

type TableRowFriendsProps = {
  referral: IRefferal,
  last: boolean,
  style?: CSSProperties
};

export default function TableRowFriends({ referral, last, style }:TableRowFriendsProps) {
  const { earning_per_hour, experience, photo_url, is_premium, username, league, reward_silver } = referral;
  return (
    <div className={`${styles.TableRowFriends} ${last ? styles.last : ""}`} style={style}>
      <div className={styles.Character}>
        <div className={styles.Avatar}>
          <Avatar color={getLeagueColorById(league)} photo={photo_url} />
        </div>

        <div className={styles.FriendInfo}>
          <div className={styles.Header}>
            <div className={styles.Title}>
              { username }
            </div>
            {is_premium && <div className={styles.Rank}>
              {<icons.starIcon width={18} height={18} />}
            </div>}
          </div>
          <div className={styles.Footer}>
            <div className={styles.XPIcon}>
              {<icons.xpIcon width={22} height={22}/>}
            </div>
            <div className={styles.Income}>
              +{ convertNumber(earning_per_hour) }
            </div>
            <div className={styles.TotalXP}>
              ({ convertNumber(Math.floor(experience)) })
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.Rewards}>
        <div className={styles.RewardIcon}>
          { icons.silverIcon }
        </div>
        <div className={styles.Reward}>
          +{convertNumber(reward_silver)}
        </div>
      </div>
    </div>
  )
}
