import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import styles from './EventBar.module.css';
import {ActiveEventEnum} from "../../../interfaces/enums";
import { useTranslation } from 'react-i18next';

type EventBarProps = {
  isCompleted: boolean,
  active: boolean,
  title: string,
  name: ActiveEventEnum,
  onActive: (activeEvent: ActiveEventEnum) => void,
  timestamp: number | null,
  children?: React.ReactNode,
  comingSoon?: boolean,
  disabled?: boolean,
  style?: CSSProperties,
};

const format = (vals: number[]) =>
  vals.map((v) => v.toString().padStart(2, '0')).join(':');

export default function EventBar({
  children,
  active,
  onActive,
  title,
  name,
  isCompleted,
  style,
  timestamp,
  disabled = false,
  comingSoon = false
}:EventBarProps) {
  const {t} = useTranslation('ns1');

  const calculateTime = (): null | number => {
    if (timestamp == null) {
      return null;
    }

    const currentDateSeconds = Math.floor(new Date().getTime() / 1000);
    const changedTime = timestamp - currentDateSeconds;

    if (changedTime < 0) {
      return null;
    } else {
      return changedTime;
    }
  }

  const [time, setTime] = useState<number | null>(calculateTime());

  function getFormatTime(secondsDuration: number) {
    const hours = Math.floor(secondsDuration / 3600);
    const minutes = Math.floor(secondsDuration % 3600 / 60);
    const seconds = Math.floor(secondsDuration % 3600 % 60);

    return [hours, minutes, seconds]
  }

  const intervalId = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    intervalId.current = setInterval(() => {
      const time = calculateTime();
      setTime(time);
    }, 1000);

    return () => {
      if (intervalId.current != null) {
        clearInterval(intervalId.current)
      }
    }
  }, [isCompleted, timestamp])

  return (
    <div
      onTouchStart={(e: React.TouchEvent) => {
        e.stopPropagation();

        if (comingSoon || disabled) {
          return;
        }

        onActive(name)
      }}
      className={`${styles.EventBar} ${active ? styles.active : ""} ${comingSoon ? styles.EventBarComingSoon : ''}`}
      style={style}
    >
      {
        comingSoon && (
          <div className={styles.comingSoon}></div>
        )
      }
      <div className={styles.Thumbnail}>
        <div className={styles.Icon}>
          { children ?? <div className={styles.Empty}></div> }
        </div>
      </div>
      <div className={styles.Info}>
        <div className={styles.Title}>
          {t(`${title}`)}
        </div>
        {
          comingSoon && (
            <div className={styles.ComingSoonTitle}>
              {t('Soon')}
            </div>
          )
        }
        <div className={`${styles.Timer} ${isCompleted ? styles.completed : ""}`} >
          { isCompleted && time ? format(getFormatTime(time)) : ""}
        </div>
      </div>
    </div>
  )
}
