import { CSSProperties, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './BoostModal.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import getPathAndAlt from '../../../utils/pictures';
import { Boost } from '../../../interfaces/boost.type';
import convertNumber from '../../../utils/numbers';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {getUserData, userInfo} from '../../../store/user';
import { loadShop, purchasedBooster, updateBooster } from '../../../store/shop';
import ConfirmPurchase from '../ConfirmPurchase';
import { useTranslation } from 'react-i18next';

type BoostModalProps = {
  boost: Boost,
  open: boolean,
  onClose: (active: boolean) => void,
  style?: CSSProperties
};
const knockDownOptions = [ 10, 50, 80, 160, 320 ];

export default function BoostModal({ onClose, open, boost }:BoostModalProps ) {
  const dispatch = useAppDispatch();
  const tg: WebApp = window.Telegram.WebApp;
  const {t} = useTranslation('ns1');
  const user = useAppSelector(getUserData());
  const { name, price_silver, next_level, max_level, image, bg_color, description, max_in_inventory, price_star } = boost;
  const { src: src2, alt: alt2 } = getPathAndAlt("XTR");
  const [ isValid, setIsValid ] = useState(false);
  const [ show, setShow ] = useState(false);
  const [active, setActive] = useState<number>(knockDownOptions[0]);

  const displayListOptions = (opts: number[]) => {
    if (!opts) return;
    return opts.map( (option) => {
      return (
        <button
          key={option}
          className={`${styles.Hit} ${active === option ? styles.active : ""}`}
          onClick={() => setActive(active === option ? active : option)}
        >
          {option}
        </button>
      )
    } )
  };

  const validate = (item: Boost) => {
    let isValid = false;

    if (item.price_star > 0) {
      return true;
    }

    if (!user?.user_wallet.silver) {
      return isValid;
    }
    if (item.max_in_inventory === 1000000000 || user?.user_wallet.silver >= item.price_silver) {
      isValid = true;
    }
    if (!boost.available) {
      isValid = false;
    }
    return isValid;
  };

  const definitionCurrency = (item: Boost) => {
    if (item.price_silver > 0) {
      return "silver"
    }
    if (item.price_star > 0) {
      return "star"
    }
    if (item.price_ton > 0) {
      return "ton"
    }
    return "silver";
  };

  useEffect(() => {
    if (validate(boost)) setIsValid(true);
    if (!validate(boost)) setIsValid(false);
  }, [boost, user]);

  const purchaseCallback = (status: "paid" | "cancelled" | "failed" | "pending") => {
    if (status === "paid") {
      setShow(true);
      setTimeout(() => {
        dispatch(userInfo());
      }, 5000);
    }
  };

  const handlePurchase = async() => {
    
    try {
      if (max_level) {
        const response: any = await dispatch(updateBooster({
          booster_id: boost.id,
          currency: definitionCurrency(boost)
        }));
        if (response.status) setShow(true);
      } else {

        const response: any = await dispatch(purchasedBooster({
          booster_id: boost.id,
          currency: definitionCurrency(boost),
          amount: max_in_inventory === 1000000000 ? active : max_in_inventory,
        }));

        if (response.invoiceLink) {
          tg.openInvoice(response.invoiceLink, purchaseCallback as any);
        } else {
          if (response.status) setShow(true);
        }
      }
      
      setTimeout(() => {
        dispatch(loadShop());
      }, 100);

    } catch (e: any) {
     console.log(e);
    }
  };

  return createPortal(
    <>
      <div className={`${styles.BoostModal} ${open ? styles.show : ""}`} >
        <div
          className={styles.Overlay}
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
          }}
        />
        <div className={styles.Body}>
          <div className={styles.Header}>
          <button
              className={styles.Arrow}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              { icons.arrowIcon }
            </button>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.PictureWrapper}>
              <div className={styles.Light} style={{ background: bg_color }}></div>
              <div className={styles.Picture}>
                <img
                  className={styles.Image}
                  src={image}
                  alt={name}
                />
              </div>
            </div>
            {name && <h1 className={styles.Title}>{name}</h1>}
            {description && <div className={styles.Text}>{description}</div>}
          </div>
          { max_in_inventory === 1000000000 && <ul className={styles.List}>
            { displayListOptions(knockDownOptions) }
          </ul>}
          <div className={styles.Info}>
            <div className={styles.Box}>
              <div className={styles.HeaderBox}>
                {t('Price')}
              </div>
              <div className={styles.PriceBox}>
                <div className={styles.EarnIcon}>
                  { max_in_inventory === 1000000000 ? <img src={src2} alt={alt2} /> : icons.silverIcon }
                </div>
                <div className={styles.Price}>
                  { convertNumber(max_in_inventory === 1000000000 ? price_star * active : price_silver) }
                </div>
              </div>
            </div>
            { max_in_inventory !== 1000000000 && max_level && (
              <div className={styles.Box}>
                <div className={styles.HeaderBox}>
                  {t('Level')}
                </div>
                <div className={styles.FooterBox}>
                  { next_level ?? 1 }
                </div>
              </div>
            )}
          </div>
          <div className={styles.WrapperSecond}>
            <MainButton
              heading='Buy'
              onTouch={handlePurchase}
              disabled={!isValid}
            />
          </div>
        </div>
        <ConfirmPurchase
          label={name}
          open={show}
          onClose={setShow}
        />
      </div>
    </>,
    document.getElementById('daily') as HTMLElement);
}
