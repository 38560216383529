import styles from "../../MainPage.module.css";
import React, {FC, useEffect, useRef} from "react";
import getRandomAnimation from "../../../../../utils/animations";
import {getUserData, updateUserData, userInfo} from "../../../../../store/user";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {IUser} from "../../../../../interfaces/user.type";
import {SpinePlayer} from "@esotericsoftware/spine-player";
import {ILeague} from "../../../../../interfaces/league.type";
import {syncSparringTapWithServer} from "../../../../../store/game";
import {createTapElement} from '../../utils'

const sparringAtlasUrl = './assets/animation/Sparring/Sparring.atlas';
const sparringJsonUrl = './assets/animation/Sparring/Sparring.json';

type Props = {
  sparringComplete: boolean;
  setSparringComplete: React.Dispatch<React.SetStateAction<boolean>>;
  onTimer: boolean;
  setOnTimer: React.Dispatch<React.SetStateAction<boolean>>;
  currentHP: number;
  maxTapPerSecond: number;
  currentLeague: ILeague | undefined;
  currentTapPerSecond: number;
  setCurrentTapPerSecond: React.Dispatch<React.SetStateAction<number>>;
  powerTap: number;
  setCurrentHP: React.Dispatch<React.SetStateAction<number>>;
  maxHP: number | undefined;
  exp: React.MutableRefObject<number>
}

const MAX_MULTITAP_COUNT = 5;

export const SparringSection: FC<Props> = ({
  sparringComplete,
  setSparringComplete,
  setOnTimer,
  currentHP,
  maxTapPerSecond,
  currentLeague,
  currentTapPerSecond,
  powerTap,
  setCurrentHP,
  maxHP,
  onTimer,
  exp,
}) => {
  const tg: WebApp = window.Telegram.WebApp;
  const initUser = useAppSelector(getUserData()) as IUser;
  const isVibration = initUser?.user_setting.vibration;
  const sparringPlayer = useRef<SpinePlayer | null>(null);
  const sparringPlayerIsReady = useRef<boolean>(false);
  const countTapRef = useRef<number>(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    sparringPlayer.current = new SpinePlayer("sparring", {
      jsonUrl: sparringJsonUrl,
      atlasUrl: sparringAtlasUrl,
      premultipliedAlpha: true,
      preserveDrawingBuffer: false,
      showControls: false,
      defaultMix: 0,
      showLoading: false,
      animations: ["Idle", "Hit1", "Hit2", "Tired"],
      animation: sparringComplete ? "Tired" : "Idle",
      alpha: true,
      backgroundColor: "#00000000",
      viewport: {
        x: 0,
        y: 0,
        padRight: 0,
        padTop: 150,
        padBottom: 150,
        padLeft: 0
      },
      scale: 1,
      success: () => {
        sparringPlayerIsReady.current = true;
      }
    });
  }, []);

  const syncServerSparring = () => {
    if (countTapRef.current > 0) {
      dispatch(syncSparringTapWithServer({
        taps: countTapRef.current,
        timestamp: Math.floor(Date.now() / 1000),
        usedEnergy: Number(countTapRef.current * Number(powerTap))
      })).then(({experienceReward, user}) => {
        if (experienceReward) {
          exp.current = exp.current + experienceReward;
          dispatch(updateUserData(user));
        }
      })
    }
    countTapRef.current = 0;
  };

  const syncAnimation = () => {
    if (!sparringPlayer.current || !sparringPlayerIsReady.current) {
      return
    }

    if (!sparringComplete) {
      sparringPlayer.current?.setAnimation("Idle", true);
    }
  };

  useEffect(() => {
    if (onTimer) {
      const sparringTimeout = setTimeout(syncServerSparring, 1000);
      const animationTimeout = setTimeout(syncAnimation, 1000);

      return () => {
        clearTimeout(sparringTimeout);
        clearTimeout(animationTimeout);
      };
    }
  }, [onTimer]);

  useEffect(() => {
    return () => {
      if (countTapRef.current > 0) {
        dispatch(syncSparringTapWithServer({
          taps: countTapRef.current,
          timestamp: Math.floor(Date.now() / 1000),
          usedEnergy: Number(countTapRef.current * Number(powerTap))
        }))
          .then(({user}) => {
            dispatch(updateUserData(user))
          });
      } else {
        dispatch(userInfo());
      }

      countTapRef.current = 0;
    }
  }, [])

  useEffect(() => {
    if (sparringComplete && sparringPlayer.current && sparringPlayerIsReady.current) {
      sparringPlayer.current.setAnimation("Tired", true);
    }
  }, [sparringComplete]);

  const handleTouchStartSparring = (e: React.TouchEvent) => {
    e.stopPropagation();

    if (!sparringPlayerIsReady.current || !sparringPlayer.current || sparringComplete) {
      return;
    }

    if (currentHP <= 0) {
      setSparringComplete(true);
    }

    if (isVibration) {
      tg.HapticFeedback.impactOccurred("soft");
    }

    setOnTimer(false);
    sparringPlayer.current?.setAnimation(getRandomAnimation(['Hit1', 'Hit2', 'Hit3', 'Hit4']), false);
    if (currentTapPerSecond < maxTapPerSecond) {

      const touches = e.touches.length >= MAX_MULTITAP_COUNT ? MAX_MULTITAP_COUNT : e.touches.length;

      for (let i = 0; i < touches; i++) {
        const touch = e.touches[i];
        createTapElement({
          touch,
          powerTap,
          type: 'sparring',
          containerId: 'sparring'
        });
      }

      countTapRef.current = countTapRef.current + touches;

      setCurrentHP((prev: number) => {
        if (Number(prev) >= Number(currentLeague?.sparring.tap_strength ?? 0) * touches) {
          const value = Number(prev ?? maxHP) - Number(currentLeague?.sparring.tap_strength ?? 0) * touches;

          if (value <= 0) {
            setSparringComplete(true);
            return 0;
          }

          return Number(prev ?? maxHP) - Number(currentLeague?.sparring.tap_strength ?? 0) * touches;
        }

        setSparringComplete(true);
        return 0;
      });
    }
  };

  const handleTouchEndSparring = (e: React.TouchEvent) => {
    e.stopPropagation();
    setOnTimer(true);
  };

  return (
    <div
      className={styles.Player}
      onTouchStart={sparringComplete ? undefined :  handleTouchStartSparring}
      onTouchEnd={handleTouchEndSparring}
      id="sparring"
      style={{ width: '100%' }}
    />
  )
}