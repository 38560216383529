import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./createStore";
import shopService from "../services/shop.service";
import {updateUserData} from "./user";

const initialState = {
  boosts: [],
  silverPacks: [],
  lastBuyedShop: null,
  invoiceLink: "",
  isLoading: false,
  error: null,
  dataLoaded: false,
  isSuccessBuy: false
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    shopRequested: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    shopRequestSuccess: (state, action) => {
      state.boosts = action.payload.boosters;
      state.silverPacks = action.payload.coinPacks;
      state.isLoading = false;
      state.dataLoaded = true;
    },
    shopRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    buySilverRequested: (state) => {
      state.isLoading = true;
      state.isSuccessBuy = false;
      state.error = null;
    },
    buySilverRequestSuccess: (state, action) => {
      state.lastBuyedShop = action.payload;
      state.invoiceLink = action.payload.invoiceLink;
      state.isLoading = false;
      state.isSuccessBuy = true;
    },
    buySilverRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isSuccessBuy = false;
    },
    buyBoosterRequested: (state) => {
      state.isLoading = true;
      state.isSuccessBuy = false;
      state.error = null;
      state.invoiceLink = "";
    },
    buyBoosterRequestSuccess: (state, action) => {
      state.lastBuyedShop = action.payload;
      state.invoiceLink = action.payload.invoiceLink;
      state.isLoading = false;
      state.isSuccessBuy = true;
    },
    buyBoosterRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isSuccessBuy = false;
    },
    updateBoosterRequested: (state) => {
      state.isLoading = true;
      state.isSuccessBuy = false;
      state.error = null;
    },
    updateBoosterRequestSuccess: (state, action) => {
      state.lastBuyedShop = action.payload;
      state.isLoading = false;
      state.isSuccessBuy = true;
    },
    updateBoosterRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isSuccessBuy = false;
    }
  }
});

const { actions, reducer: shopReducer } = shopSlice;
const {
  shopRequested,
  shopRequestSuccess,
  shopRequestFailed,
  buySilverRequested,
  buySilverRequestSuccess,
  buySilverRequestFailed,
  buyBoosterRequested,
  buyBoosterRequestSuccess,
  buyBoosterRequestFailed,
  updateBoosterRequested,
  updateBoosterRequestSuccess,
  updateBoosterRequestFailed
} = actions;


export const loadShop = () => async (dispatch: any) => {
  dispatch(shopRequested());
  try {
    const { content } = await shopService.fetchAll();
    dispatch(shopRequestSuccess(content));
  } catch (error: any) {
      dispatch(shopRequestFailed(error.message));
  }
};
export const purchasedSilver = (payload: object) => async (dispatch: any) => {
  dispatch(buySilverRequested());
  try {
    const { content } = await shopService.buySilver(payload);
    dispatch(buySilverRequestSuccess(content));
    dispatch(updateUserData(content.user))
    return content;
  } catch (error: any) {
      dispatch(buySilverRequestFailed(error.message));
   
  }
};
export const purchasedBooster = (payload: object) => async (dispatch: any) => {
  dispatch(buyBoosterRequested());
  try {
    const { content } = await shopService.buyBooster(payload);
    dispatch(buyBoosterRequestSuccess(content));
    dispatch(updateUserData(content.user));
    return content;
  } catch (error: any) {
      dispatch(buyBoosterRequestFailed(error.message));
  }
};
export const updateBooster = (payload: object) => async (dispatch: any) => {
  dispatch(updateBoosterRequested());
  try {
    const { content } = await shopService.updateBooster(payload);
    dispatch(updateBoosterRequestSuccess(content));
    dispatch(updateUserData(content.user));
    return content;
  } catch (error: any) {
      dispatch(updateBoosterRequestFailed(error.message));
  }
};


export const getShopLoadingStatus = () => (state: RootState) => state.shop.isLoading;
export const getShopDataStatus = () => (state: RootState) => state.shop.dataLoaded;
export const getShopBoostsData = () => (state: RootState) => state.shop.boosts;
export const getShopSilverPacksData = () => (state: RootState) => state.shop.silverPacks;
export const getShopInvoiceLinkData = () => (state: RootState) => state.shop.invoiceLink;
export const getShopLastBuyData = () => (state: RootState) => state.shop.lastBuyedShop;
export const getShopLastBuyStatus = () => (state: RootState) => state.shop.isSuccessBuy;
export const getShopErrors = () => (state: RootState) => state.shop.error;

export default shopReducer;
