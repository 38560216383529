import { CSSProperties } from 'react';
import styles from './Toggle.module.css';

type ToggleProps = {
  isToggled: boolean,
  onToggle: () => void,
  style?: CSSProperties
};

export default function Toggle({ isToggled, onToggle }: ToggleProps) {

  return (
    <label className={styles.Toggle}>
      <input type="checkbox" checked={isToggled} onChange={onToggle}/>
      <span className={styles.Switch} />
    </label>
  )
}
