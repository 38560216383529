function convertNumber(number: number | string | undefined) {
  if (!number) return 0;
  if (typeof number === 'string') return number;
  const THOUSAND = 1000;
  const MILLION = 1000000;
  const BILLION = 1000000000;

  if (number < THOUSAND) {
    return number;
  }
  if (number / THOUSAND < THOUSAND) {
    return `${number / THOUSAND}K`
  }
  if (number / MILLION < THOUSAND) {
    return `${number / MILLION}M`
  }
  if (number / BILLION < THOUSAND) {
    return `${number / BILLION}B`
  }
};
export default convertNumber;