import React, { CSSProperties, useState } from 'react';
import styles from './DailyCard.module.css';
import getPathAndAlt from '../../../utils/pictures';
import icons from '../../../utils/icons';
import XPsvg from "../../../assets/icons/XP.svg?react";
import DailyModal from '../DailyModal';
import convertNumber from '../../../utils/numbers';
import { useTranslation } from 'react-i18next';

type DailyCardProps = {
  image?: string,
  reward_currency: "exp" | "silver",
  title: string,
  description: string,
  modalText: string,
  reward_amount: number,
  status: boolean,
  style?: CSSProperties,
  disabled?: boolean,
  props?: React.HTMLAttributes<HTMLButtonElement>
};

export default function DailyCard({ image, reward_amount, status, reward_currency, title, description, modalText, style, disabled, ...props }:DailyCardProps) {
  const [show, setShow] = useState(false);
  const {t} = useTranslation('ns1');
  const { src, alt } = getPathAndAlt(image);
  const leftColor = '#2D3339';
  
  // let rightColor = '#6B859F80';

  function switchColorBG(type:string | undefined) {
    switch (type) {
      case "calendar":
        return "rgba(159, 148, 107, 0.50)";
      default:
        return "#6B859F80";
    }
  }

  const stl = {
    ...style,
    background: `linear-gradient(90deg, ${leftColor} 0%, ${leftColor} 50%, ${status ? "#6F9F6B80"  : switchColorBG(image)} 100%)`
  };

  const handleOpenModal = () => {
    setShow(true);
  };
  
  return (
    <>
      <button
        onClick={handleOpenModal}
        className={styles.DailyCard}
        style={stl}
        disabled={disabled}
        {...props}
      >
        <div className={styles.Wrap}>
          <div className={styles.Title}>
            {t(`${title}`)}
          </div>
          <div className={styles.Info}>
            <div className={`${styles.RewardIcon} ${reward_currency === 'silver' ? styles.silver : ""}`}>
              { reward_currency === 'exp' ? <XPsvg />  : icons.earnIcon }
            </div>
            <div className={styles.Reward}>
              +{convertNumber(reward_amount)}
            </div>
            <div className={styles.Description}>
              {t(`${description}`)}
            </div>
          </div>
        </div>
        <div className={styles.Icon}>
          {status ? icons.doneIcon  : <img src={src} alt={alt} /> }
        </div>
      </button>
      <DailyModal
        label={title}
        text={modalText}
        content='calendar'
        open={show}
        onClose={()=>setShow(false)}
      />
    </>
  )
}
