import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./createStore";
import cardService from "../services/card.service";
import { Card } from "../interfaces/card.type";
import {updateUserData} from "./user";

type SliceState = {
  entities: Record<string, Card[]> | null,
  lastBuyedCard: Card | null,
  isLoading: boolean,
  error: any,
  dataLoaded: boolean,
  isSuccessBuy: boolean
}
const initialState: SliceState = {
  entities: null,
  lastBuyedCard: null,
  isLoading: false,
  error: null,
  dataLoaded: false,
  isSuccessBuy: false
};

const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    cardsRequested: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    cardsRequestSuccess: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
      state.dataLoaded = true;
    },
    cardsRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    buyCardRequested: (state) => {
      state.isLoading = true;
      state.isSuccessBuy = false;
      state.error = null;
    },
    buyCardRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.isSuccessBuy = true;
      state.dataLoaded = true;
      state.entities = action.payload;
    },
    buyCardRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

const { actions, reducer: cardsReducer } = cardsSlice;
const {
  cardsRequested,
  cardsRequestSuccess,
  cardsRequestFailed,
  buyCardRequested,
  buyCardRequestSuccess,
  buyCardRequestFailed
} = actions;


export const loadCards = () => async (dispatch: any) => {
  dispatch(cardsRequested());
  try {
    const { content } = await cardService.fetchAll();
    dispatch(cardsRequestSuccess(content.cards));
  } catch (error: any) {
    dispatch(cardsRequestFailed(error.message));
  }
};
export const purchasedCard = (payload: object) => async (dispatch: any) => {
  dispatch(buyCardRequested());
  try {
    const { content } = await cardService.buy(payload);
    dispatch(buyCardRequestSuccess(content.cards));
    dispatch(updateUserData(content.user));
  } catch (error: any) {
    dispatch(buyCardRequestFailed(error.message));
  }
};


export const getCardsLoadingStatus = () => (state: RootState) => state.cards.isLoading;
export const getCardsDataStatus = () => (state: RootState) => state.cards.dataLoaded;
export const getCardsData = () => (state: RootState) => state.cards.entities;
export const getCardLastBuyData = () => (state: RootState) => state.cards.lastBuyedCard;
export const getCardsErrors = () => (state: RootState) => state.cards.error;

export default cardsReducer;
