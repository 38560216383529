import httpService from "./http.service.ts";

const precheckoutEndpoint = 'airdrop-transaction/precheckout';
const processEndpoint = 'airdrop-transaction/process';

const airdropTransactionService = {
    precheckout: async (sourceAddressUserFriendly: string, sourceAddress: string) => {
        const {data} = await httpService.post(precheckoutEndpoint, {
            sourceAddressUserFriendly,
            sourceAddress,
        });
        return data;
    },
    process: async (hash: string, boc: string) => {
        const {data} = await httpService.post(processEndpoint, {hash, boc});
        return data;
    },
}

export default airdropTransactionService;