import httpService from "./http.service";

const ratingEndpoint = "rating";
const ratingLeaguesEndpoint = "rating/leagues";

const ratingService = {
  fetchById: async (payload: string) => {
    const { data } = await httpService.get(ratingEndpoint, {
      params: {
        league_id: payload
      }
    });
    return data;
  },
  fetchAll: async () => {
    const { data } = await httpService.get(ratingLeaguesEndpoint);
    return data;
  },
};

export default ratingService;
