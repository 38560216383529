import {memo, useState} from 'react';
import styles from './TonButton.module.css';
import {TonConnectAndDailyTransactionModal} from "../TonConnectAndDailyTransactionModal";
import {useSelector} from "react-redux";
import {getUserData} from "../../../store/user.ts";
import icons from '../../../utils/icons.tsx';

export default memo(function TonButton() {
    const [show, setShow] = useState(false);
    const userData = useSelector(getUserData());

    if (userData?.user_daily_transaction?.state === 'completed') {
        return null;
    }

    return (
        <>
            <button
                className={styles.TonButton}
                onClick={() => setShow(true)}
            >
                <div className={styles.Image}>
                    <icons.toncoint3dIcon />
                </div>
            </button>
            <TonConnectAndDailyTransactionModal open={show} onClose={() => setShow(false)} />
        </>
    )
})
