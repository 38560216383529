import { CSSProperties } from 'react';
import styles from './InviteContent.module.css';
import icons from '../../../utils/icons';
import TableRow from '../TableRow';
import { useAppSelector } from '../../../hooks/hooks';
import { getLeaguesData } from '../../../store/rating';
import { ILeague } from '../../../interfaces/league.type';
import { useTranslation } from 'react-i18next';
import InviteCard from '../InviteCard';

type InviteContentProps = {
  style?: CSSProperties
};

const inviteItems = [
  {
    id: "1",
    title: "Referral friend reward",
    description: "Your reward",
    reward_currency: 'silver',
    reward_amount: 1,
    type: 'gift'
  },
  { 
    id: "2",
    title: "Refferal with Telegram Premium",
    description: "Your reward",
    reward_currency: 'silver',
    reward_amount: 2,
    type: 'star'
  }
];

export default function InviteContent({ style }:InviteContentProps) {
  const leagues = useAppSelector(getLeaguesData());
  const {t} = useTranslation('ns1');

  const displayLeagues = (leagues: ILeague[] | null) => {
    if (!leagues) return;
    return leagues.map((league: ILeague, index:number) => (
      <TableRow
        key={league.id}
        payload={league}
        last={index === (leagues.length - 1)}
      />
    ))
  };

  return (
    <div className={styles.InviteContent} style={style}>
      <ul className={styles.List}>
        {inviteItems.map((item) => (
          <InviteCard
            key={item.id}
            title={item.title}
            description={item.description}
            reward_currency={item.reward_currency}
            reward_amount={item.reward_amount}
            type={item.type}
          />
        ))}
        </ul>
        <div className={styles.Warning}>
          <div className={styles.InfoIcon}>
            { icons.infoIcon }
          </div>
          <div className={styles.Text}>
            {t('attention about reward')}
          </div>
        </div>
        <h4 className={styles.TableHeading}>{t('Bonuses for moving to the next league')}</h4>
        <div className={styles.Table}>
          <div className={styles.Header}>
            <div className={styles.Column}>
              {t('league')}
            </div>
            <div className={styles.Column}>
              {t('for referral')}
            </div>
            <div className={styles.Column}>
              {t('premium')}
            </div>
          </div>
          <div className={styles.Body}>
            { displayLeagues(leagues) }
          </div>
        </div>
    </div>
  )
}
