import { CSSProperties } from 'react';
import styles from './ComboContent.module.css';
import icons from '../../../utils/icons';

type ComboContentProps = {
  onHex: (id: string) => void,
  style?: CSSProperties
};
type Hexgon = {
  id: string
};
const list: Hexgon[] = [
  { id: "1" },
  { id: "2" },
  { id: "3" },
  { id: "4" },
  { id: "5" },
  { id: "6" }
];


export default function ComboContent({ onHex, style }:ComboContentProps) {
  
  const displayHexgons = (items: Hexgon[]) => {
    return items.map((item: Hexgon) => (
      <div
        key={item.id}
        className={styles.Hex}
        data-value={item.id}
        onTouchStart={() => onHex(item.id)}
      >
        {icons.hexgonIcon}
      </div>
    ));
  };
  return (
    <div
      className={styles.ComboContent}
      style={style}
    >
      { displayHexgons(list) }
    </div>
  )
}
