import { CSSProperties } from 'react';
import styles from './TableRow.module.css';
import icons from '../../../utils/icons';
import Avatar from '../Avatar';
import convertNumber from '../../../utils/numbers';
import { ILeague } from '../../../interfaces/league.type';
import {getLeagueColorById} from "../../../utils/colors";

type TableRowProps = {
  payload: ILeague,
  last: boolean,
  style?: CSSProperties
};

export default function TableRow({ payload, last, style }:TableRowProps) {
  const { title, referral_reward, id } = payload;
  const { simple_silver, premium_silver } = referral_reward;
  return (
    <div className={`${styles.TableRow} ${last ? styles.last : ""}`} style={style}>
      <div className={styles.Character}>
        <div className={styles.Avatar}>
          <Avatar color={getLeagueColorById(id)} />
        </div>
        <div className={styles.Title}>
          { title }
        </div>
      </div>
      <div className={styles.Rewards}>
        <div className={styles.RewardIcon}>
          { icons.silverIcon }
        </div>
        <div className={styles.Reward}>
          +{convertNumber(simple_silver)}
        </div>
      </div>
      <div className={styles.Rewards}>
        <div className={styles.RewardIcon}>
          { icons.silverIcon }
        </div>
        <div className={styles.Reward}>
          +{convertNumber(premium_silver)}
        </div>
      </div>
    </div>
  )
}
