import { CSSProperties, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './CardConfirm.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import convertNumber from '../../../utils/numbers';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getUserData } from '../../../store/user';
import { purchasedCard } from '../../../store/cards';
import { useTranslation } from 'react-i18next';

type CardConfirmProps = {
  id: string,
  text?: string,
  label: string,
  price: number,
  profit: number,
  imgPath: string,
  open: boolean,
  bgColor: string,
  onClose: (active: boolean) => void,
  style?: CSSProperties
};

export default function CardConfirm({ onClose, imgPath, open, price, profit, label, text, id, bgColor }:CardConfirmProps ) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('ns1');
  const currentEXP = useAppSelector(getUserData())?.experience;
  const [isValid, setIsValid] = useState(false);

  const validate = (userXP: number, price: number) => {
    return userXP >= price;
  };
  const handlePurchase = (id: string) => {
    dispatch(purchasedCard({ id: id }));
    onClose(false);
  };
  useEffect(() => {
    if (currentEXP || price) setIsValid(validate(Number(currentEXP), price))
  },[currentEXP, price])

  return createPortal(
    <>
      <div className={`${styles.CardConfirm} ${open ? styles.show : ""}`} >
        <div
          className={styles.Overlay}
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
          }}
        />
        <div className={styles.Body}>
          <div className={styles.Header}>
            <button
              className={styles.CloseBtn}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              { icons.timesIcon }
            </button>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Picture}>
              <div className={styles.Light} style={{backgroundColor: bgColor}}></div>
              <img
                className={styles.Image}
                src={imgPath}
                alt={label}
              />
            </div>
            {label && <h1 className={styles.Title}>{label}</h1>}
            {text && <div className={styles.Text}>{text}</div>}
          </div>
          <div className={styles.Info}>
            <div className={styles.Box}>
              <div className={styles.HeaderBox}>
                {t('Price')}
              </div>
              <div className={styles.PriceBox}>
                <div className={styles.ExpIcon}>
                  <icons.xpIcon height={24} width={24} />
                </div>
                <div className={styles.Price}>
                  { convertNumber(price) }
                </div>
              </div>
            </div>
            <div className={styles.Box}>
              <div className={styles.HeaderBox}>
                {t('Gain')}
              </div>
              <div className={styles.FooterBox}>
                +{ convertNumber(profit) }
              </div>
            </div>
          </div>
          <div className={styles.WrapperSecond}>
            <MainButton
              disabled={!isValid}
              heading='Upgrade'
              onTouch={() => handlePurchase(id)}
              />
          </div>
        </div>
      </div>
    </>,
    document.getElementById('confirm') as HTMLElement);
}
