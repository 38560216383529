import React, { CSSProperties } from 'react';
import styles from './MainButton.module.css';
import { useTranslation } from 'react-i18next';

interface MainButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string,
  heading: string,
  successBG?: boolean, 
  isLoading?: boolean, 
  onTouch: (id?: string) => void,
  style?: CSSProperties
};

const MainButton: React.FC<MainButtonProps> = ({ id, heading, onTouch, successBG, isLoading, style, ...props }) => {
  const {t} = useTranslation('ns1');
  return (
    <button
      className={`${styles.MainButton} ${ successBG ? styles.success : "" }`}
      onClick={() => onTouch(id)}
      style={style}
      {...props}
    >
      {  !isLoading ? t(`${heading}`) : <div className={styles.Loader}></div>}
    </button>
  )
}

export default MainButton;
