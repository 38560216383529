import {CSSProperties, useRef, useState} from 'react';
import styles from './InstructionPage.module.css';
import {useHistory, useLocation} from 'react-router-dom';
import MainButton from "../../ui/MainButton";
import {ROUTES} from "../../../routes";
import Instruction1ImgSrc from '../../../assets/images/instruction_1.webp';
import Instruction2ImgSrc from '../../../assets/images/instruction_2.webp';
import Instruction3ImgSrc from '../../../assets/images/instruction_3.webp';
import Instruction4ImgSrc from '../../../assets/images/instruction_4.webp';
import Instruction5ImgSrc from '../../../assets/images/instruction_5.webp';
import gameService from "../../../services/game.service";
import {useSelector} from "react-redux";
import {getUserData} from "../../../store/user";
import {IUser} from "../../../interfaces/user.type";
import { useTranslation } from 'react-i18next';

type InstructionPageProps = {
  style?: CSSProperties
};
type Instruction= {
  id: number,
  title: string,
  image?: string
};

const list: Instruction[] = [
  { id: 1, title: "Welcome to the world of MMA. Play, become a professional fighter, and receive your AirDrop!", image: Instruction1ImgSrc  },
  { id: 2, title: "Earn experience points on the bag, with a sparring partner, and in other game sections.", image: Instruction2ImgSrc  },
  { id: 3, title: "Expand your team and improve the training process for your character to gain the experience faster", image: Instruction3ImgSrc  },
  { id: 4, title: "Earn silver coins by completing daily combos and tasks to purchase upgrades", image: Instruction4ImgSrc  },
  { id: 5, title: "The size of your AirDrop will depend on your ranking and the amount of the earned silver and gold coins", image: Instruction5ImgSrc  }
];

export default function InstructionPage({}:InstructionPageProps ) {
  const location = useLocation()
  const listRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const {t} = useTranslation('ns1');
  const [currentPage, setCurrentPage] = useState(1);
  const userData = useSelector(getUserData());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const displayList = (items: Instruction[]) => {
    return items.map((item: Instruction) => {
      return (
        <div className={`${styles.Wrapper} ${item.id === currentPage ? styles.WrapperActive : ''}`} key={item.id}>
          <div className={styles.Image}>
            <div className={styles.Light}></div>
            <img src={item.image} alt="Instruction img" />
          </div>
          <div className={styles.InfoWrapper}>
            <div className={styles.Title}>{t(`Instruction ${String(item.id)}`) ?? item.title}</div>
            <div className={styles.Indicator} >
              { displayDots(list.length) }
            </div>
            <div className={styles.ContinueBtn}>
              <MainButton
                heading={isLastPage ? 'Get started' : 'Continue'}
                isLoading={isLoading}
                onTouch={onClickButton}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  const displayDots = (count: number ) => {
    let indicatorArray: number[] = [];
    for (let i = 1; i < count + 1; i++) {
      indicatorArray.push(i);
    }
    return indicatorArray.map(el => {
      return (<div key={el} className={`${styles.circle} ${el === currentPage ? styles.red : ""}`}></div>);
    })
  };

  const isLastPage = currentPage === list.length

  const onClickButton = () => {
    if (!isLastPage) {
      setCurrentPage((prev) => prev + 1);
      return;
    }

    if (location?.state?.isOnboarding) {
      setIsLoading(true)
      gameService.changeSettings({...(userData as IUser).user_setting, is_onboarding_complete: true})
        .finally(() => {
          setIsLoading(false)
          history.push(ROUTES.root)
        })
    } else {
      history.push(ROUTES.settings.root);
    }
  }

  return (
    <div className={styles.InstructionPage} >
        <div className={styles.List} ref={listRef}>
            { displayList(list) }
        </div>
    </div>
  )
}
