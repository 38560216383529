import { CSSProperties, useState } from 'react';
import styles from './ShopButton.module.css';
// import shop from "../../../assets/icons/Shop.svg";
import ShopModal from '../ShopModal';
import pictures from "../../../utils/pictures";

type ShopButtonProps = {
  color?: string,
  style?: CSSProperties
};

export default function ShopButton({ color = '#EB4141', style, ...props }:ShopButtonProps) {
  const [show, setShow] = useState(false);
  const imgAttrs = pictures('shop');
  return (
    <>
      <button
        className={styles.ShopButton}
        onClick={() => setShow(true)}
        {...props}
      >
        <div className={styles.Image}>
          <img src={imgAttrs.src} alt={imgAttrs.alt} />
        </div>
      </button>
      <ShopModal
        label='Shop'
        text='shop description'
        content='shop'
        open={show}
        onClose={()=>setShow(false)}
      />
    </>
  )
}
