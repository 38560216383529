const TOKEN_KEY = "jwt-mmator-token";
const REFRESH_KEY = "jwt-mmator-refresh-token";
const EXPIRES_KEY = "jwt-mmator-expires";
const EXPIRES_REFRESH_KEY = "jwt-mmator-expires-refresh";

type Tokens = {
  accessToken: string,
  refreshToken?: string,
  expireAccess?: number,
  expireRefresh?: number
}

export function setTokens({
  accessToken,
  refreshToken = "refresh-empty",
  expireAccess = 3600,
  expireRefresh = 108000
}: Tokens) {
  const expiresAccessDate = String(new Date().getTime() + (expireAccess - 300) * 1000); //За 5 минут до окончания
  const expiresRefreshDate = String(new Date().getTime() + expireRefresh * 1000);

  localStorage.setItem(TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_KEY, refreshToken);
  localStorage.setItem(EXPIRES_KEY, expiresAccessDate);
  localStorage.setItem(EXPIRES_REFRESH_KEY, expiresRefreshDate);
}
export function getAccessToken(): string {
  return String(localStorage.getItem(TOKEN_KEY));
}
export function getRefreshToken() {
  return localStorage.getItem(REFRESH_KEY);
}
export function getExpiresAccessDate() {
  return localStorage.getItem(EXPIRES_KEY);
}
export function getExpiresRefreshDate() {
  return localStorage.getItem(EXPIRES_REFRESH_KEY);
}
export function removeAuthData() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_KEY);
  localStorage.removeItem(EXPIRES_KEY);
  localStorage.removeItem(EXPIRES_REFRESH_KEY);
}

const localStorageService = {
  setTokens,
  getAccessToken,
  getRefreshToken,
  getExpiresRefreshDate,
  getExpiresAccessDate,
  removeAuthData
};

export default localStorageService;
