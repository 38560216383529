import { CSSProperties } from 'react';
import styles from './ComboBar.module.css';
import icons from '../../../utils/icons';

type ComboBarProps = {
  combo: string[],
  comboIsCompleted?: boolean,
  style?: CSSProperties
};

export default function ComboBar({ combo, comboIsCompleted, style }:ComboBarProps) {
  
const array = ["1","2","3","4","5"];
const displayRectangles = (combo: string[]) => {
  return array.map((el: string, index: number) => (
    <div key={el} className={styles.Rectangle}>
      {index <= (combo.length - 1) && !comboIsCompleted && <div className={styles.Icon}>
        { icons.helmetIcon }
      </div>}
    </div>
  ))
};
  return (
    <div className={styles.ComboBar} style={style}>
      { displayRectangles(combo) }
    </div>
  )
}
