import { CSSProperties, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './TaskModal.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import getPathAndAlt from '../../../utils/pictures';
import convertNumber from '../../../utils/numbers';
import { useAppDispatch } from '../../../hooks/hooks';
import { checkedTask } from '../../../store/tasks';
import { useTranslation } from 'react-i18next';

type TaskModalProps = {
  id: string,
  title: string,
  type: string | undefined,
  description?: string,
  link: string,
  open: boolean,
  reward_amount: number,
  reward_currency: 'silver' | 'gold' | 'exp',
  user_task_status: 'started' | 'available' | "rewarded",
  onClose: (active: boolean) => void,
  style?: CSSProperties
};

export default function TaskModal({ onClose, open, id, link, user_task_status, title, description, type, reward_amount }:TaskModalProps ) {
  const tg: WebApp = window.Telegram.WebApp;
  const dispatch = useAppDispatch();
  const {t} = useTranslation('ns1');
  const { src, alt } = getPathAndAlt(type);
  const [ isSubscribed, setSubscribed ] = useState(user_task_status === "started");

  const handleSubscribe = (type: string | undefined, id: string) => {
    dispatch(checkedTask(id))
    if (type === 'telegram') {
      tg.openTelegramLink(link);
    } else {
      tg.openLink(link, { try_instant_view: false });
    }
    setTimeout(()=> {
      setSubscribed(true);
    }, 1000);
  };
  const handleCheckSubscribe = (id: string) => {
    dispatch(checkedTask(id))
    setTimeout(()=> {
      setSubscribed(false);
      onClose(false);
    }, 1000);
  };

  return createPortal(
    <>
      <div className={`${styles.TaskModal} ${open ? styles.show : ""}`} >
        <div
          className={styles.Overlay}
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
          }}
        />
        <div className={styles.Body}>
          <div className={styles.Header}>
            <button
              className={styles.CloseBtn}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              { icons.timesIcon }
            </button>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Picture}>
              <img
                className={styles.Image}
                src={src}
                alt={alt}
              />
            </div>
            {title && <h1 className={styles.Title}>{title}</h1>}
            {description && <div className={styles.Text}>{description}</div>}
          </div>
          <div className={styles.Info}>
            <div className={styles.Box}>
              <div className={styles.HeaderBox}>
                {t('Reward')}
              </div>
              <div className={styles.PriceBox}>
                <div className={styles.EarnIcon}>
                  { icons.earnIcon }
                </div>
                <div className={styles.Price}>
                  +{ convertNumber(reward_amount) }
                </div>
              </div>
            </div>
          </div>
          <div className={styles.WrapperSecond}>
            <MainButton
              heading={isSubscribed ? 'You are subscribed' : 'Subscribe' }
              onTouch={() => handleSubscribe(type, id)}
              disabled={user_task_status === "rewarded" || user_task_status === 'started'}
              style={{ marginBottom: '16px' }}
            />
            <MainButton
              heading='Check the reward'
              onTouch={() => handleCheckSubscribe(id)}
              successBG={true}
              disabled={user_task_status !== "started"}
            />
          </div>
        </div>
      </div>
    </>,
    document.getElementById('daily') as HTMLElement);
}
