import { createSlice } from "@reduxjs/toolkit";
import {PunchingTap, Tap} from "../interfaces/tap.type";
import gameService from "../services/game.service";
import { ISnackBar } from "../interfaces/snackBar.type";
import { RootState } from "./createStore";
import { Reward } from "../interfaces/reward.type";
import {updateUserData} from "./user";

// const mockRewards: Reward[] = [
//   {
//     day: 1,
//     title: "Day 1",
//     reward: 5000,
//     status: "available"
//   },
//   {
//     day: 2,
//     title: "Day 2",
//     reward: 10000,
//     status: "not_available"
//   },
//   {
//     day: 3,
//     title: "Day 3",
//     reward: 15000,
//     status: "not_available"
//   },
//   {
//     day: 4,
//     title: "Day 4",
//     reward: 20000,
//     status: "not_available"
//   },
//   {
//     day: 5,
//     title: "Day 5",
//     reward: 25000,
//     status: "not_available"
//   },
//   {
//     day: 6,
//     title: "Day 6",
//     reward: 30000,
//     status: "not_available"
//   },
//   {
//     day: 7,
//     title: "Day 7",
//     reward: 35000,
//     status: "not_available"
//   },
//   {
//     day: 8,
//     title: "Day 8",
//     reward: 40000,
//     status: "not_available"
//   },
//   {
//     day: 9,
//     title: "Day 9",
//     reward: 45000,
//     status: "not_available"
//   },
//   {
//     day: 10,
//     title: "Day 10",
//     reward: 50000,
//     status: "not_available"
//   },
//   {
//     day: 11,
//     title: "Day 11",
//     reward: 55000,
//     status: "not_available"
//   },
//   {
//     day: 12,
//     title: "Day 12",
//     reward: 5500000,
//     status: "not_available"
//   }
// ];

type SliceState = {
  isLoading: boolean,
  isRewardLoading: boolean,
  data: null | object,
  sparring: any,
  error: any,
  dailyLoaded: boolean
  snackbar: null | ISnackBar,
  isShow: boolean,
  dailyRewards: Reward[] | null,
  checkCombo: boolean,
  comboDataStatus: boolean,
  combo: null | string
}

const initialState: SliceState = {
  isLoading: false,
  isRewardLoading: false,
  error: null,
  data: null,
  sparring: null,
  dailyLoaded: false,
  snackbar: null,
  isShow: false,
  dailyRewards: [],
  checkCombo: false,
  comboDataStatus: false,
  combo: null,
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    tapRequested: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    tapRequestSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    tapRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    sparringTapRequested: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    sparringTapRequestSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    sparringTapRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    sparringKnockdownRequested: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    sparringKnockdownRequestSuccess: (state) => {
      state.isLoading = false;
    },
    sparringKnockdownRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    dailyRequested: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    dailyRequestSuccess: (state, action) => {
      state.dailyRewards = action.payload;
      state.isLoading = false;
      state.dailyLoaded = true;
    },
    dailyRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rewardRequested: (state) => {
      state.error = null;
      state.isRewardLoading = true;
    },
    rewardRequestSuccess: (state, action) => {
      state.isRewardLoading = false;
      state.dailyRewards = action.payload;
    },
    rewardRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isRewardLoading = false;
    },
    snackActivated: (state, action) => {
      state.snackbar = action.payload;
      state.isShow = true;
    },
    snackDeactivated: (state) => {
      state.isShow = false;
    },
    comboRequested: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    comboRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.combo = action.payload;
      state.comboDataStatus = true;
    },
    comboRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    comboCheckRequested: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    comboCheckRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.checkCombo = action.payload;
    },
    comboCheckRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  }
});

const { actions, reducer: gameReducer } = gameSlice;
const {
  tapRequested,
  tapRequestSuccess,
  tapRequestFailed,
  snackActivated,
  snackDeactivated,
  dailyRequested,
  dailyRequestSuccess,
  dailyRequestFailed,
  rewardRequested,
  rewardRequestSuccess,
  rewardRequestFailed,
  sparringTapRequested,
  sparringTapRequestSuccess,
  sparringTapRequestFailed,
  sparringKnockdownRequested,
  sparringKnockdownRequestSuccess,
  sparringKnockdownRequestFailed,
  comboRequested,
  comboRequestSuccess,
  comboRequestFailed,
  comboCheckRequested,
  comboCheckRequestSuccess,
  comboCheckRequestFailed
} = actions;


export const syncTapWithServer = ( payload: PunchingTap ) => async (dispatch: any) => {
  dispatch(tapRequested());
  try {
    const { content } = await gameService.tap(payload);
    dispatch(tapRequestSuccess(content));
  } catch (error: any) {
    dispatch(tapRequestFailed(error.message));
  }
};
export const syncSparringTapWithServer = ( payload: Tap ) => async (dispatch: any) => {
  dispatch(sparringTapRequested());
  try {
    const { content } = await gameService.sparringTap(payload);
    dispatch(sparringTapRequestSuccess(content));
    return content
  } catch (error: any) {
    dispatch(sparringTapRequestFailed(error.message));
  }
};
export const usedKnockdown = ( payload: object ) => async (dispatch: any) => {
  dispatch(sparringKnockdownRequested());
  try {
    const { content } = await gameService.useKnockdown(payload);
    dispatch(sparringKnockdownRequestSuccess(content));
    dispatch(updateUserData(content.user));
  } catch (error: any) {
    dispatch(sparringKnockdownRequestFailed(error.message));
  }
};
export const loadDailyRewards = () => async (dispatch: any) => {
  dispatch(dailyRequested());
  try {
    const { content } = await gameService.fetchDaily();
    dispatch(dailyRequestSuccess(content.rewards));
  } catch (error: any) {
    dispatch(dailyRequestFailed(error.message));
  }
};

export const checkDailyReward = () => async (dispatch: any, getState: any) => {
  dispatch(rewardRequested());
  try {
    const { content } = await gameService.getReward();
    dispatch(rewardRequestSuccess(content.rewards));
    const currentExperience = getState().user.user.experience;
    dispatch(updateUserData({experience: currentExperience + content.reward}))
  } catch (error: any) {
    dispatch(rewardRequestFailed(error.message));
  }
};
export const loadDailyCombo = () => async (dispatch: any) => {
  dispatch(comboRequested());
  try {
    const { content } = await gameService.fetchCombo();
    dispatch(comboRequestSuccess(content.combo));
  } catch (error: any) {
    dispatch(comboRequestFailed(error.message));
  }
};
export const checkDailyCombo = (payload: object) => async (dispatch: any) => {
  dispatch(comboCheckRequested());
  try {
    const { content } = await gameService.checkCombo(payload);
    dispatch(comboCheckRequestSuccess(content));
    dispatch(updateUserData(content.user));
  } catch (error: any) {
    dispatch(comboCheckRequestFailed(error.message));
  }
};
export const showSnackBar = ( payload: ISnackBar ) => (dispatch: any) => {
  dispatch(snackActivated(payload));
};
export const hideSnackBar = () => (dispatch: any) => {
  dispatch(snackDeactivated());
};


export const getGameSnackBarData = () => (state: RootState) => state.game.snackbar;
export const getGameSnackBarShowStatus = () => (state: RootState) => state.game.isShow;
export const getGameDailyRewardsData = () => (state: RootState) => state.game.dailyRewards;
export const getGameDailyRewardsLoading = () => (state: RootState) => state.game.isRewardLoading;
export const getGameDailyRewardsStatus = () => (state: RootState) => state.game.dailyLoaded;
export const getGameCombosStatus = () => (state: RootState) => state.game.comboDataStatus;
export const getGameComboData = () => (state: RootState) => state.game.combo;
export const getGameComboCheck = () => (state: RootState) => state.game.checkCombo;


export default gameReducer;
