import {CSSProperties} from 'react';
import { createPortal } from 'react-dom';
import styles from './Notification.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import getPathAndAlt from '../../../utils/pictures';
import convertNumber from '../../../utils/numbers';
import { useTranslation } from 'react-i18next';
import {useAppSelector} from "../../../hooks/hooks";
import {getUserEarned} from "../../../store/user";

type NotificationProps = {
  open: boolean,
  onClose: () => void,
  style?: CSSProperties
};

export default function Notification({ onClose, open }:NotificationProps ) {
  const { src, alt } = getPathAndAlt("exp");
  const {t} = useTranslation('ns1');
  const earnExperience = useAppSelector(getUserEarned())?.experience;

  return createPortal(
    <>
      <div className={`${styles.Notification} ${open ? styles.show : ""}`} >
        <div
          className={styles.Overlay}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        />
        <div className={styles.Body}>
          <div className={styles.Header}>
            <button
              className={styles.CloseBtn}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              { icons.timesIcon }
            </button>
          </div>
          <div className={styles.Wrapper}>
            <div className={styles.Picture}>
              <div className={styles.PriceBox}>
                <div className={styles.EarnIcon}>
                  <img src={src} alt={alt}  />
                </div>
                <div className={styles.Price}>
                  { convertNumber(Math.floor(earnExperience || 0)) }
                </div>
              </div>
            </div>
            <h1 className={styles.Title}>{t('You have earned')}!</h1>
            <div className={styles.Text}>
              {t('Experience accumulated')}
            </div>
          </div>
          <div className={styles.BottomWrap}>
            <MainButton
              heading='Lets go'
              onTouch={() => onClose()}
            />
          </div>
        </div>
      </div>
    </>,
    document.getElementById('confirm') as HTMLElement);
}
