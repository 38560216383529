import {FightWithActiveEvent} from "../interfaces/fight.type";
import { Friend } from "../interfaces/friend.type";
import { Offer } from "../interfaces/offer.type";
import { Player } from "../interfaces/player.type";
import { Rank } from "../interfaces/rank.type";
import { Task } from "../interfaces/task.type";
import icons from "./icons";
import {ActiveEventEnum} from "../interfaces/enums";

export const nationalities = [
  { id: "1", title: "European", color: "blue" },
  { id: "2", title: "Asian", color: "pink" },
  { id: "3", title: "Latino", color: "blue" },
  { id: "4", title: "Arab", color: "yellow" },
  { id: "5", title: "African American", color: "green" },
  { id: "6", title: "Caucasian", color: "white" },
];
export const navItems = [
  { id: "1", title: "Fight", icon: icons.fightIcon, path: '/fight' },
  { id: "2", title: "Training", icon: icons.trainingIcon, path: '/training' },
  { id: "3", title: "", icon: icons.mainIcon, path: '/' },
  { id: "4", title: "Club", icon: icons.clubIcon, path: '/club' },
  { id: "5", title: "Earn", icon: icons.earnIcon, path: '/earn' }
];
export const taskItems: Task[] = [
  { id: "1",
    title: "Join our Telegram channel",
    description: "Subscribe",
    link: "Subscribe",
    reward_currency: 'silver',
    reward_amount: 300,
    type: 'telegram',
    user_task_status: 'available'
  },
  { id: "2",
    title: "Join our Youtube channel",
    description: "Subscribe",
    link: "Subscribe",
    reward_currency: 'silver',
    reward_amount: 300,
    type: 'youtube',
    user_task_status: 'available'
  }
];
export const changeItems: Task[] = [
  { id: "1",
    title: "+100 Coins",
    description: "Purchase",
    link: "Purchase",
    reward_currency: 'exp',
    reward_amount: 50000,
    type: 'silver',
    user_task_status: 'available'
  },
  { id: "2",
    title: "+200 Coins",
    description: "Purchase",
    link: "Purchase",
    reward_currency: 'exp',
    reward_amount: 100000,
    type: 'stack',
    user_task_status: 'available'
  }
];
export const inviteItems: Task[] = [
  { id: "1",
    title: "Invite friend",
    link: "Invite friend",
    description: "For you and your friend",
    reward_currency: 'silver',
    reward_amount: 2000,
    type: 'gift',
    user_task_status: 'available'
  },
  { id: "2",
    title: "Invite friend with Telegram Premium",
    link: "For you and your friend",
    description: "For you and your friend",
    reward_currency: 'silver',
    reward_amount: 2000,
    type: 'star',
    user_task_status: 'available'
  }
];
export const fightEventItems: FightWithActiveEvent[] = [
  {
    id: "1",
    title: "Daily combo attack",
    reward: 50,
    rewardType: 'silver',
    context: "combo",
    activeEvent: ActiveEventEnum.DAILY_COMBO,
  },
  {
    id: "2",
    title: "Sparring",
    context: "sparring",
    rewardType: 'experience',
    activeEvent: ActiveEventEnum.SPARRING,
  }
];
export const ranksFights: Rank[] = [
  { id: "1",
    title: "Young man",
    defaultReward: 10,
    premiumReward: 20,
    minXP: 0,
    maxXP: 10000
  },
  { id: "2",
    title: "Adult",
    defaultReward: 30,
    premiumReward: 40,
    minXP: 10001,
    maxXP: 50000
  },
  { id: "3",
    title: "Candidate Masterssssssssssssssssssss",
    defaultReward: 50,
    premiumReward: 60,
    minXP: 50001,
    maxXP: 100000
  },
  { id: "4",
    title: "Pro",
    defaultReward: 70,
    premiumReward: 800000,
    minXP: 100001,
    maxXP: 150000
  }
];
export const mocksFriends: Friend[] = [
  { id: "1",
    name: "BlackMamba",
    passiveIncome: 10500,
    playerProfit: 200,
    totalXP: 35000,
    rank: { title:"Young" }
  },
  { id: "2",
    name: "WhiteRabbit",
    passiveIncome: 76300,
    playerProfit: 1200,
    totalXP: 35000000,
    rank: {title:"Pro"}
  },
  { id: "3",
    name: "RedFox",
    passiveIncome: 10500,
    playerProfit: 450,
    totalXP: 350000,
    rank: {title:"Candidate"}
  },
  { id: "4",
    name: "BlackMCatssssssssssssssssssssssssssss",
    passiveIncome: 55000,
    playerProfit: 2200,
    totalXP: 3500000000,
    rank: {title:"Pro"}
  }
];
export const mocksPlayers: Player[] = [
  { id: "1",
    name: "BlackMamba",
    passiveIncome: 10500,
    totalXP: 35000,
    rank: { title:"Pro" }
  },
  { id: "2",
    name: "WhiteRabbit",
    passiveIncome: 76300,
    totalXP: 35000000,
    rank: {title:"Pro"}
  },
  { id: "3",
    name: "RedFox",
    passiveIncome: 10500,
    totalXP: 350000,
    rank: {title:"Pro"}
  },
  { id: "4",
    name: "BlackMCatssssssssssssssssssssssssssss",
    passiveIncome: 55000,
    totalXP: 120000,
    rank: {title:"Pro"}
  }
];

export const currentPlayer: Player = {
  id: "4",
  name: "BlackMCatssssssssssssssssssssssssssss",
  passiveIncome: 55000,
  totalXP: 120000,
  rank: {title:"Pro"}
};

// export const rewardList: Reward[] = [
//   { id: "1", reward: "50K" },
//   { id: "2", reward: "100K" },
//   { id: "3", reward: "150K" },
//   { id: "4", reward: "200K" },
//   { id: "5", reward: "250K" },
//   { id: "6", reward: "300K" },
//   { id: "7", reward: "350K" },
//   { id: "8", reward: "400K" },
//   { id: "9", reward: "450K" }
// ];
 
export const offerList:Record<string, Offer[]> = {
  "silver": [
    {
      id: "1",
      price: 50000,
      discount: 0,
      currency: 'exp'
    },
    {
      id: "2",
      price: 0.17,
      discount: 10,
      currency: 'TON'
    },
    {
      id: "3",
      price: 0.89,
      discount: 0,
      currency: 'USDT'
    },
    {
      id: "4",
      price: 50,
      discount: 0,
      currency: 'XTR'
    },
  ],
  "stack": [
    {
      id: "1",
      price: 100000,
      discount: 0,
      currency: 'exp'
    },
    {
      id: "2",
      price: 0.34,
      discount: 10,
      currency: 'TON'
    },
    {
      id: "3",
      price: 1.78,
      discount: 0,
      currency: 'USDT'
    },
    {
      id: "4",
      price: 100,
      discount: 0,
      currency: 'XTR'
    },
  ]
};
// export const boostList: Boost[] = [
//   {
//     id: "1",
//     title: "Knockout",
//     text: "10/50/100/400",
//     description: 'The more hits you buy the more points you earn in the fight',
//     basicPrice: 120500,
//     context: 'range',
//     rangeOptions: [10, 50 , 100, 400],
//     picture: "knockout"
//   },
//   {
//     id: "2",
//     title: "Main trainer",
//     text: "",
//     description: 'Brief description of the boost. Sample text for filling. Brief description of the boost.',
//     basicPrice: 80000,
//     context: 'level',
//     level: 3,
//     picture: "karate"
//   },
//   {
//     id: "3",
//     title: "Increased energy stack",
//     text: "",
//     description: 'Brief description of the boost. Sample text for filling. Brief description of the boost.',
//     basicPrice: 80000,
//     context: 'level',
//     level: 2,
//     picture: "energy"
//   },
//   {
//     id: "4",
//     title: "Punching bag tap power",
//     text: "",
//     description: 'Brief description of the boost. Sample text for filling. Brief description of the boost.',
//     basicPrice: 30000,
//     context: 'level',
//     level: 5,
//     picture: "punch"
//   },
// ];

export const getWidthProgressValue = ( current: number | undefined , min: number | undefined, max: number | undefined) => {
  if (!current || (!min && min !== 0) || !max) return 0;

  if (current < max) {
    return (current - min )/ (max - min) * 100
  }
  return 100;
};