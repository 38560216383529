import {CSSProperties, FC, useEffect} from 'react';
import { createPortal } from 'react-dom';
import styles from './TonConnectAndDailyTransactionModal.module.css'
import icons from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import {SecondButton} from "../SecondButton";
import {useAppSelector} from "../../../hooks/hooks.ts";
import MainButton from "../MainButton";
import {useSendDailyTonTransaction} from "../../../hooks";
import {
    ConnectedWallet,
    toUserFriendlyAddress,
    useTonConnectModal,
    useTonConnectUI,
    useTonWallet
} from "@tonconnect/ui-react";
import {useHistory} from "react-router-dom";
import {ROUTES} from "../../../routes";
import {getUserData} from "../../../store/user.ts";
import ImgSrc from '../../../assets/images/daily-transaction-logo.webp'
import gameService from "../../../services/game.service.ts";

type TonConnectAndDailyTransactionModalProps = {
    open: boolean,
    onClose: () => void,
    style?: CSSProperties
};

export const TonConnectAndDailyTransactionModal: FC<TonConnectAndDailyTransactionModalProps> = ({ onClose, open }) => {
    const {t} = useTranslation('ns1');
    const history = useHistory();
    const userData = useAppSelector(getUserData());
    const wallet = useTonWallet();
    const {onSendTransaction, isLoading} = useSendDailyTonTransaction();
    const [tonConnectUI] = useTonConnectUI();
    const {open: tonConnectModalOpen} = useTonConnectModal()

    const isCompleted = userData?.user_daily_transaction.state === 'completed';

    const gold = userData?.ton_rewards.user_daily_transaction?.reward_gold;
    const silver = userData?.ton_rewards.user_daily_transaction?.reward_silver;

    const connectCallback = async (connectedWallet: ConnectedWallet) => {
        const address = connectedWallet.account.address
        const friendlyAddress = toUserFriendlyAddress(address);
        await gameService.walletConnect({
            source_address: address,
            source_address_user_friendly: friendlyAddress,
        })
        onClose();
        history.push(ROUTES.earn.root);
    }

    useEffect(() =>
        tonConnectUI.onStatusChange(tonWallet => {
            if (tonWallet && open) {
                connectCallback(tonWallet)
            }
    }), [open]);

    return createPortal(
        <>
            <div className={`${styles.TonConnectAndDailyTransactionModal} ${open ? styles.show : ""}`} >
                <div
                    className={styles.Overlay}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                    }}
                />
                <div className={styles.Body}>
                    <div className={styles.Header}>
                        <button
                            className={styles.CloseBtn}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClose();
                            }}
                        >
                            { icons.timesIcon }
                        </button>
                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Picture}>
                            <div className={styles.BG}>
                                <img src={ImgSrc} alt="Alt" />
                            </div>
                            <div className={styles.LeftLight}></div>
                            <div className={styles.TopLight}></div>
                        </div>
                        <div className={styles.InnerWrapper}>
                            <h1 className={styles.Title}>
                                {
                                    wallet
                                        ? t(`${isCompleted ? 'Congratulation' : 'Daily TON transaction'}`)
                                        : t('Connect TON wallet')
                                }
                            </h1>
                            <div className={styles.Text}>
                                {
                                    wallet
                                        ? t(`${isCompleted ? 'Your transaction was successful! The reward has already been awarded to you!' : 'Complete a transaction on the TON network and receive a reward'}`)
                                        : t('To participate in AirDrop, you need to connect a TON wallet.')
                                }
                            </div>
                            {
                                wallet && (
                                    <div className={styles.RewardWrapper}>
                                        <span className={styles.RewardTitle}>{t('Reward')}</span>
                                        <div className={styles.RewardList}>
                                            {
                                                silver && (
                                                    <div className={styles.RewardItem}>
                                                        <div className={`${styles.RewardIcon}`}>
                                                            {icons.silverIcon}
                                                        </div>
                                                        <div className={styles.Reward}>
                                                            +{silver}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                gold && (
                                                    <div className={styles.RewardItem}>
                                                        <div className={`${styles.RewardIcon}`}>
                                                            {icons.goldIcon}
                                                        </div>
                                                        <div className={styles.Reward}>
                                                            +{gold}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={styles.WrapperSecond}>
                        {
                            !wallet && (
                                <MainButton heading="Connect" onTouch={() => tonConnectModalOpen()}/>
                            )
                        }
                        {
                            wallet && (
                                isCompleted ? (
                                    <MainButton heading="Done" successBG={true} onTouch={() => onClose()}/>
                                ) : (
                                    <SecondButton
                                        text={isLoading ? "Transaction Processing" : "Complete the task"}
                                        onClick={onSendTransaction}
                                        isLoading={isLoading}
                                    />
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </>,
        document.getElementById('daily') as HTMLElement);
}
