import React, { CSSProperties, useState } from 'react';
import styles from './TaskCard.module.css';
import getPathAndAlt from '../../../utils/pictures';
import icons from '../../../utils/icons';
import XPsvg from "../../../assets/icons/XP.svg?react";
import { Task } from '../../../interfaces/task.type';
import convertNumber from '../../../utils/numbers';
import TaskModal from '../TaskModal';

interface TaskCardProps extends Task  {
  style?: CSSProperties,
  disabled?: boolean,
  props?: React.HTMLAttributes<HTMLButtonElement>
}

export default function TaskCard({ id, title, link, type, user_task_status, reward_amount, reward_currency = 'exp', description, start_ts, style, disabled, ...props }:TaskCardProps) {
  const [show, setShow] = useState(false);
  const { src, alt } = getPathAndAlt(type);
  const leftColor = '#2D3339';
  // let rightColor = '#6B859F80';

  function switchColorBG(type:string | undefined) {
    switch (type) {
      case "telegram":
        return "#55C5FF80";
      case "youtube":
        return "#FE614B80";
      case "gift":
        return "#F04A6880";
      case "star":
        return "#A061BD80";
      default:
        return "#6B859F80";
    }
  }

  const stl = {
    ...style,
    background: `linear-gradient(90deg, ${leftColor} 0%, ${leftColor} 50%, ${user_task_status === 'rewarded' ? "#6F9F6B80"  : switchColorBG(type)} 100%)`
  };

  const handleOpenModal = () => {
    setShow(true);
  };
  
  return (
    <>
      <button
        onClick={handleOpenModal}
        className={styles.TaskCard}
        style={stl}
        disabled={disabled}
        {...props}
      >
        <div className={styles.Wrap}>
          <div className={styles.Title}>
            {title}
          </div>
          <div className={styles.Info}>
            <div className={`${styles.RewardIcon} ${reward_currency === 'silver' ? styles.silver : ""} ${reward_currency === 'gold' ? styles.gold : ""}`}>
              { reward_currency === 'exp' ? <XPsvg />  : icons.silverIcon }
            </div>
            <div className={styles.Reward}>
              {`${type === 'silver' || type === 'stack' ? "" : "+"}${convertNumber(reward_amount)}`}
            </div>
            <div className={`${styles.Description} ${((type === 'star') || (type === 'gift') )? styles.another : ""}`}>
              {description}
            </div>
          </div>
        </div>
        <div className={styles.Icon}>
          {user_task_status === 'rewarded' ? icons.doneIcon  : <img src={src} alt={alt} /> }
        </div>
      </button>
      {((type !== 'silver') && (type !== 'stack') && (type !== 'calendar')) && <TaskModal
        id={id}
        title={title}
        type={type}
        link={link}
        reward_currency={reward_currency}
        reward_amount={reward_amount}
        user_task_status={user_task_status}
        open={((type === 'telegram') || (type === 'youtube') || type === 'twitter') ? show : false}
        onClose={()=>setShow(false)}
      />}
    </>
  )
}
