import React, {useState, memo, useCallback, CSSProperties} from 'react';
import styles from './ProgressBar.module.css';
import Avatar from '../Avatar';
import Leaderboard from '../Leaderboard';
import {getLeagueColorById} from '../../../utils/colors';
import { ILeague } from '../../../interfaces/league.type';
import {getWidthProgressValue} from "../../../utils/helpers";

type ProgressBarProps = {
  photo?: string | null,
  username: string | undefined,
  league: ILeague | undefined,
  current: number | undefined,
  exp_per_hour: number | undefined,
  total: number | undefined,
  style?: CSSProperties
};

function ProgressBar({ current, exp_per_hour, total, league, username, photo, style }:ProgressBarProps) {
  const [show, setShow] = useState(false);
  const onClose = useCallback(() => setShow(false), []);

  return (
    <>
      <div
        className={styles.ProgressBar}
        style={style}
        onTouchStart={(e: React.TouchEvent) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <div className={styles.Avatar}>
          <Avatar color={getLeagueColorById(league?.id)} photo={photo}/>
        </div>
        <div className={styles.ProgressInfo}>
          <div className={styles.Wrapper}>
            <div className={styles.Name}>
              { username }
              <div className={styles.Status}>
                { league?.title || '' }
              </div>
            </div>
            <div className={styles.Values}>
              {current} / {total}
            </div>
          </div>
          <div className={styles.Progress}>
            <div className={styles.ProgressValue} style={{ width: `${getWidthProgressValue(exp_per_hour, league?.rules?.min_score, league?.rules?.max_score)}%` }}></div>
          </div>
        </div>
      </div>
      <Leaderboard
        content='pro'
        label='Professional'
        text=''
        open={show}
        onClose={onClose}
      />
    </>
  )
}

export default memo(ProgressBar);
