import { CSSProperties, useEffect } from 'react';
import styles from './SnackBar.module.css';
import icons from '../../../utils/icons';
import { createPortal } from 'react-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getGameSnackBarData, getGameSnackBarShowStatus, hideSnackBar } from '../../../store/game';
import { ISnackBar } from '../../../interfaces/snackBar.type';
import { useTranslation } from 'react-i18next';

type SnackBarProps = {
  style?: CSSProperties
};

export default function SnackBar({ style }:SnackBarProps) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('ns1');
  const options: ISnackBar | null = useAppSelector(getGameSnackBarData());
  const isShow = useAppSelector(getGameSnackBarShowStatus());

  // const { message, status, duration } = options;
  // let stl;

  // if (position==="top") {
  //   stl = {
  //     ...style,
  //     top: "10%"
  //   };
  // } else {
  //   stl = {
  //     ...style,
  //     top: "90%"
  //   };
  // }
  const handleHideSnackBar = () => {
    dispatch(hideSnackBar());
  };

  useEffect(() => {
    if (isShow) {
      const snackbarTimeout = setTimeout(handleHideSnackBar, options?.duration ?? 1500);
    
      return () => {clearTimeout(snackbarTimeout)};
    }
  }, [options, dispatch, isShow]);

  return createPortal(
    <>
      <div
        className={`${styles.SnackBar} ${isShow ? styles.show : ""}`}
        style={style}
      >
        <div className={`${styles.Status} ${options?.status === 'success' ? styles.success : styles.error}`}>
          { icons.successIcon }
        </div>
        <div className={styles.Message}>
          {t(`${options?.message}`)}
        </div>
      </div>
    </>,
    document.getElementById('snackbar') as HTMLElement);
}
