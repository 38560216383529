import { CSSProperties } from 'react';
import styles from './EnergyBar.module.css';
import LightingSVG from "../../../assets/icons/Lightning.svg?react";
import KnockdownButton from '../KnockdownButton';
import { useAppSelector } from '../../../hooks/hooks';
import { getUserData } from '../../../store/user';
import { Boost } from '../../../interfaces/boost.type';

type EnergyBarProps = {
  current: number | null,
  total: number | undefined,
  sparringIsCompleted?: boolean,
  knockdown?: boolean,
  onKnockdown: () => void,
  style?: CSSProperties
};

export default function EnergyBar({ current, knockdown, total, onKnockdown, sparringIsCompleted, style }:EnergyBarProps) {
  const user = useAppSelector(getUserData());
  const countKnockdown = user?.user_boosters?.find((boost: Boost) => boost.id === "5")?.in_inventory;
  const condition = Number(countKnockdown ?? 0) > 0;

  return (
    <div className={styles.EnergyBar} style={style}>
      {knockdown && <KnockdownButton count={countKnockdown || 0} onKnockdown={onKnockdown} disabled={!condition || sparringIsCompleted} />}
      <div className={styles.Wrapper}>
        <div className={styles.Energy}>
          <div className={styles.Silver}>
            <div className={styles.Amount}>
              {Math.floor(Number(current))} / {Number(total)}
            </div>
          </div>
          <div className={styles.Gold}>
            <div className={styles.LightningIcon}>
              <LightingSVG />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
