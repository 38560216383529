import React from "react";
import LightingSVG from "../../../../assets/icons/Lightning.svg";
import XPSVG from "../../../../assets/icons/XP.svg";

import './taps.css';

type Options = {
  touch: React.Touch,
  powerTap: number,
  containerId: string,
  type: 'punching-bag' | 'sparring',
}

const iconMapper = {
  'sparring': LightingSVG,
  'punching-bag': XPSVG,
}

export function createTapElement({touch, powerTap, containerId, type}: Options) {
  // Создаем элемент тапа
  const tap = document.createElement('div');
  tap.classList.add('power-tap');
  tap.innerHTML = `
      <img src="${iconMapper[type]}" alt="icon" />
      <span>${type === 'punching-bag' ? '+' : ''}${powerTap}</span>
    `;

  // Находим элемент, по которому было касание
  const element = document.getElementById(containerId)!;
  const rect = element.getBoundingClientRect();

  // Устанавливаем тап на позицию касания относительно элемента
  tap.style.left = `${touch.clientX - rect.left}px`;
  tap.style.top = `${touch.clientY - rect.top}px`;

  // Добавляем тап к элементу
  element.appendChild(tap);

  // Удаляем тап после завершения анимации
  setTimeout(() => {
    tap.remove();
  }, 1000); // Длительность анимации 1 секунда
}