import React, { CSSProperties, useState } from 'react';
import styles from './ChangeCard.module.css';
import { SilverPack } from '../../../interfaces/silverPack.type';
import ChangeModal from '../ChangeModal';
import { useTranslation } from 'react-i18next';

interface ChangeCardProps {
  style?: CSSProperties,
  options: Pick<SilverPack, "id" | "amount">[],
  items: SilverPack[],
  props?: React.HTMLAttributes<HTMLButtonElement>
};

export default function ChangeCard({ options, items, style, ...props }:ChangeCardProps) {
  const [show, setShow] = useState(false);
  const {t} = useTranslation('ns1');
  const leftColor = '#2D3339';

  const stl = {
    ...style,
    background: `linear-gradient(90deg, ${leftColor} 0%, ${leftColor} 50%, #6B859F80 100%)`
  };

  const handleOpenModal = () => {
    setShow(true);
  };

  return (
    <>
      <button
        onClick={handleOpenModal}
        className={styles.ChangeCard}
        style={stl}
        {...props}
      >
        <div className={styles.Wrap}>
          <div className={styles.Title}>
            {t('Buy a pack of silver coins')}
          </div>
          <div className={styles.Info}>
            <div className={styles.Description}>
              {t('Purchase')}
            </div>
          </div>
        </div>
        <div className={styles.Icon}>
          <img
            src="https://assets.mmator.online/shop/silver_coins/3.webp"
            alt="Silver coins"
          />
        </div>
      </button>
      <ChangeModal
        label={"Silver coin packets"}
        text={"text changemodal"}
        options={options}
        items={items}
        open={show}
        onClose={()=>setShow(false)}
      />
    </>
  )
}
