import {FC, ButtonHTMLAttributes} from 'react'
import styles from './SecondButton.module.css'
import {useTranslation} from "react-i18next";

type SecondButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    success?: boolean,
    isLoading?: boolean
    text: string
}

export const SecondButton: FC<SecondButtonProps> = ({success, text, isLoading, ...rest}) => {
    const {t} = useTranslation('ns1');

    return (
        <button
            {...rest}
            disabled={isLoading}
            className={`${styles.SecondButton} ${success ? styles.success : ""}`}
        >
            {t(text)}
            {
                isLoading && <div className={styles.Loader}></div>
            }
        </button>
    )
}