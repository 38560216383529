import {CSSProperties, FC, useEffect} from 'react';
import { createPortal } from 'react-dom';
import styles from './TonConnectDailyModal.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import { useTranslation } from 'react-i18next';
import {
    toUserFriendlyAddress,
    useTonConnectModal,
    useTonConnectUI,
    useTonWallet
} from "@tonconnect/ui-react";
import {SecondButton} from "../SecondButton";
import gameService from "../../../services/game.service.ts";

type TonConnectDailyModalProps = {
    open: boolean,
    onClose: (active: boolean) => void,
    style?: CSSProperties
};

export const TonConnectDailyModal: FC<TonConnectDailyModalProps> = ({ onClose, open }) => {
    const {t} = useTranslation('ns1');

    const { open: openTonConnectModal } = useTonConnectModal();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();

    const isConnected = wallet != null;
    const label = isConnected ? 'Congratulations' : 'Connect TON wallet';
    const text = isConnected ? 'TON Wallet connected' : 'To participate in AirDrop, you need to connect a TON wallet.';

    useEffect(() =>
        tonConnectUI.onStatusChange(tonWallet => {
            if (tonWallet && open) {
                const address = tonWallet.account.address
                const friendlyAddress = toUserFriendlyAddress(address);
                gameService.walletConnect({
                    source_address: address,
                    source_address_user_friendly: friendlyAddress,
                })
            }
        }), [open]);

    return createPortal(
        <>
            <div className={`${styles.TonConnectDailyModal} ${open ? styles.show : ""}`} >
                <div
                    className={styles.Overlay}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose(false);
                    }}
                />
                <div className={styles.Body}>
                    <div className={styles.Header}>
                        <button
                            className={styles.CloseBtn}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClose(false);
                            }}
                        >
                            { icons.timesIcon }
                        </button>
                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Picture}>
                            <div className={styles.Light}></div>
                            {
                                isConnected
                                    ? <icons.tonConnectSuccessIcon className={styles.Image} width={100} height={100}/>
                                    : <icons.tonConnectIcon className={styles.Image} width={100} height={100}/>
                            }
                        </div>
                        {label && <h1 className={styles.Title}>{t(`${label}`)}</h1>}
                        {text && <div className={styles.Text}>{t(`${text}`)}</div>}
                    </div>
                    <div className={styles.WrapperSecond}>
                        {
                            isConnected ? (
                                <MainButton heading="Done" successBG={true} onTouch={() => onClose(false)} />
                            ) : (
                                <SecondButton
                                    text="Connect"
                                    onClick={openTonConnectModal}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </>,
        document.getElementById('daily') as HTMLElement);
}
