// import i18next from 'i18next';
import { CSSProperties, useState } from 'react';
import styles from './LanguagePage.module.css';
import icons from '../../../utils/icons';
import { useHistory } from 'react-router-dom';
import { changeSettingsUser, getUserData, getUserLanguages, updateUserData } from '../../../store/user';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { Language } from '../../../interfaces/language.type';
import { useTranslation } from 'react-i18next';

type LanguagePageProps = {
  style?: CSSProperties
};

export default function LanguagePage({  }:LanguagePageProps ) {
  // const tg: WebApp = window.Telegram.WebApp;
  const history = useHistory();
  const dispatch  = useAppDispatch();
  const listLanguages = useAppSelector(getUserLanguages());
  const settings = useAppSelector(getUserData())?.user_setting;
  const [ locale, setLocale ] = useState<string>(settings?.locale ?? "");
  const { i18n, t } = useTranslation('ns1');

  const handleSwitchLocale = (item: Language) => {
    
    if (settings?.vibration === undefined) return;
    console.log("click");
    console.log(item);
    i18n.changeLanguage(item.locale);
    setLocale(item.locale);
    dispatch(changeSettingsUser({
      locale: item.locale,
      vibration: settings?.vibration,
      is_onboarding_complete: true,
    }))
    dispatch(updateUserData({
      user_setting: {
        locale: item.locale,
        vibration: settings?.vibration,
        is_onboarding_complete: true,
      }
    }))
  };

  const displayLanguages = (items: Language[]) => {
    return items.map((item: Language, index: number) => {
      return (
        <button
          key={item.id}
          onClick={() => handleSwitchLocale(item)}
          className={`${styles.Setting} ${index === 0 ? styles.top : ""} ${index === items.length -1 ? styles.bottom : ""}`}
        >
          <div className={styles.Info}>
            <div className={styles.SettingTitle}>
              {t(`${item.title}`)}
            </div>
          </div>
          { locale === item.locale && <div className={styles.Icon}>
            <div className={styles.IconBtn}>
              { locale === item.locale ? icons.successIcon : "" }
            </div>
          </div>}
        </button>
      );
    })
  };

  return (
    <div className={styles.LanguagePage} >
      
      <div className={styles.Header}>
          <button
            className={styles.Arrow}
            onClick={(e) => {
              e.stopPropagation();
              history.push("/settings");
            }}
          >
            { icons.arrowIcon }
          </button>
          <div className={styles.HeaderTitle}>{t('Language')}</div>
          <div className={styles.Empty}></div>
        </div>
        <div className={styles.Wrapper}>
          { displayLanguages(listLanguages) }
        </div>
    </div>
  )
}
