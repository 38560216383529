import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enNs1 from './app/locales/en/ns1.json';
import ruNs1 from './app/locales/ru/ns1.json';
import kaNs1 from './app/locales/ka/ns1.json';
import deNs1 from './app/locales/de/ns1.json';
import caNs1 from './app/locales/ca/ns1.json';
import hrNs1 from './app/locales/hr/ns1.json';
import nlNs1 from './app/locales/nl/ns1.json';
import frNs1 from './app/locales/fr/ns1.json';
import heNs1 from './app/locales/he/ns1.json';
import itNs1 from './app/locales/it/ns1.json';
import kkNs1 from './app/locales/kk/ns1.json';
import msNs1 from './app/locales/ms/ns1.json';
import plNs1 from './app/locales/pl/ns1.json';
import ptNs1 from './app/locales/pt/ns1.json';
import srNs1 from './app/locales/sr/ns1.json';
import esNs1 from './app/locales/es/ns1.json';
import trNs1 from './app/locales/tr/ns1.json';
import ukNs1 from './app/locales/uk/ns1.json';
import arNs1 from './app/locales/ar/ns1.json';
import afNs1 from './app/locales/af/ns1.json';
import zhNs1 from './app/locales/zh/ns1.json';
import hiNs1 from './app/locales/hi/ns1.json';
import jaNs1 from './app/locales/ja/ns1.json';
import kyNs1 from './app/locales/ky/ns1.json';
import koNs1 from './app/locales/ko/ns1.json';
import tgNs1 from './app/locales/tg/ns1.json';
import uzNs1 from './app/locales/uz/ns1.json';
import azNs1 from './app/locales/az/ns1.json';
import hyNs1 from './app/locales/hy/ns1.json';

export const defaultNS = 'ns1';

i18next
  .use(initReactI18next)
   // @ts-ignore
  .init({
    debug: true,
    fallbackLng: 'en',
    defaultNS,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        ns1: enNs1
      },
      ru: {
        ns1: ruNs1
      },
      ka: {
        ns1: kaNs1
      },
      de: {
        ns1: deNs1
      },
      ca: {
        ns1: caNs1
      },
      hr: {
        ns1: hrNs1
      },
      nl: {
        ns1: nlNs1
      },
      fr: {
        ns1: frNs1
      },
      he: {
        ns1: heNs1
      },
      it: {
        ns1: itNs1
      },
      kk: {
        ns1: kkNs1
      },
      ms: {
        ns1: msNs1
      },
      pl: {
        ns1: plNs1
      },
      pt: {
        ns1: ptNs1
      },
      sr: {
        ns1: srNs1
      },
      es: {
        ns1: esNs1
      },
      tr: {
        ns1: trNs1
      },
      uk: {
        ns1: ukNs1
      },
      ar: {
        ns1: arNs1
      },
      af: {
        ns1: afNs1
      },
      zh: {
        ns1: zhNs1
      },
      hi: {
        ns1: hiNs1
      },
      ja: {
        ns1: jaNs1
      },
      ky: {
        ns1: kyNs1
      },
      ko: {
        ns1: koNs1
      },
      tg: {
        ns1: tgNs1
      },
      uz: {
        ns1: uzNs1
      },
      az: {
        ns1: azNs1
      },
      hy: {
        ns1: hyNs1
      },
    },
    plural: {
      ru: (n: number) => {
        if (n % 10 === 1 && n % 100 !== 11) {
          return 'one';
        }
        if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
          return 'few';
        }
        return 'many';
      }
    }
});

export default i18next;