import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import {apiEndpoint} from "../constants";
import localStorageService from "./localStorage.service";
import {login} from "../store/user";
import {addRequestToQueue, processQueue} from "./requestQueue";

const http = axios.create({
  baseURL: apiEndpoint
});

const tg: WebApp = window.Telegram.WebApp;
const initTgUserData = tg.initData;

// Флаг для предотвращения повторных запросов авторизации
let isRefreshing = false;

http.interceptors.request.use(
  async function (config: InternalAxiosRequestConfig) {
    const accessToken = localStorageService.getAccessToken();
    
    if (accessToken) {
      config.headers.set('Authorization', `Bearer ${accessToken}`)
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (res: AxiosResponse) => {
    res.data = { content: res.data };
    return res;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

export const setUpInterceptor = (store: any) => {
  http.interceptors.response.use(
    (res) => {
      return res;
    },
    async (error) => {
      const originalRequest = error.config;

      // Если ошибка 401 и токен не был обновлен, пытаемся сделать реавторизацию
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;
          try {
            await store.dispatch(login(initTgUserData));
            isRefreshing = false;

            // Повторяем оригинальный запрос
            return http(originalRequest);
          } catch (authError) {
            isRefreshing = false;
            return Promise.reject(authError);
          } finally {
            // После авторизации или ошибки отправляем накопленные запросы
            processQueue();
          }
        } else {
          // Если уже идет процесс обновления токена, добавляем запрос в очередь
          return new Promise((resolve) => {
            addRequestToQueue(() => resolve(http(originalRequest)));
          });
        }
      }

      return Promise.reject(error);
    }
  );
}

const httpService = {
  get: http.get,
  post: http.post,
  put: http.put,
  patch: http.patch,
  delete: http.delete
};
export default httpService;
