import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import gameReducer from './game';
import cardsReducer from './cards';
import shopReducer from './shop';
import tasksReducer from './tasks';
import ratingReducer from './rating';

const rootReducer = combineReducers({
  user: userReducer,
  game: gameReducer,
  cards: cardsReducer,
  tasks: tasksReducer,
  shop: shopReducer,
  rating: ratingReducer
});

function createStore() {
  return configureStore({
    reducer: rootReducer
  });
}
export const store = createStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
