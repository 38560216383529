import { CSSProperties } from 'react';
import styles from './SwitchPanel.module.css';
import { SwitchOption } from '../../../interfaces/switchOption.type';
import { useTranslation } from 'react-i18next';

type SwitchPanelProps = {
  heading?: string,
  fontSize?: number,
  options: SwitchOption[],
  active: SwitchOption,
  onSwitch: (active: SwitchOption) => void,
  style?: CSSProperties
};

function capitalized(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export default function SwitchPanel({ heading, options, fontSize, active, onSwitch, style }:SwitchPanelProps) {

  const {t} = useTranslation('ns1');
  const renderOptions = (items: SwitchOption[]) => {
    return items.map((item) => (
      <button
        key={item.id}
        className={`${styles.Btn} ${JSON.stringify(active) === JSON.stringify(item) ? styles.active : ""}`}
        onClick={() => onSwitch(item)}
        style={{ width: `${ 100 / options.length }%`, fontSize: `${fontSize}px` }}
      >
        {t(`${capitalized(item.title)}`)}
      </button>
    ))
  };

  return (
    <div className={styles.SwitchPanel} style={style}>
      {heading && <h4>{heading}</h4>}
      <div className={styles.Background}>
        { renderOptions(options) }
      </div>
    </div>
  )
}
