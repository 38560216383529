import { useEffect, useState } from 'react';
import styles from './ClubPage.module.css';
import SwitchPanel from '../../ui/SwitchPanel';
import Navbar from '../../ui/Navbar';
import { SwitchOption } from '../../../interfaces/switchOption.type';
import icons from '../../../utils/icons';
import InviteContent from '../../ui/InviteContent';
import FriendsContent from '../../ui/FriendsContent';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getUserData } from '../../../store/user';
import { showSnackBar } from '../../../store/game';
import SnackBar from '../../ui/SnackBar';
import { useTranslation } from 'react-i18next';
import {loadDataLeagues} from "../../../store/rating";

const trainingOptions: SwitchOption[] = [
  { id:'1', title: 'Invite' },
  { id:'2', title: 'Friends' }
];

export default function ClubPage() {
  const tg: WebApp = window.Telegram.WebApp;
  const {t} = useTranslation('ns1');
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getUserData())?.id;
  const [ active, setActive ] = useState(trainingOptions[0]);

  useEffect(() => {
    dispatch(loadDataLeagues())
  }, []);

  useEffect(() => {
    if (!tg.isExpanded) tg.expand();
  }, [tg.isExpanded]);
  
  const handleInviteFriend = () => {
    const text = t('ref text');
    const link = `${import.meta.env.VITE_BOT_LINK}?startapp=ref${userId}`;
    const shareURL = "https://t.me/share/url?url=" + link + "&text=" + encodeURI( text );

    tg.openTelegramLink( shareURL );
  };
  const handleCopyToClipboard = async() => {
    const link = `${import.meta.env.VITE_BOT_LINK}?startapp=ref${userId}`;
    try {
      await navigator.clipboard.writeText( link );

      dispatch(showSnackBar({
        message: "Copied to clipboard",
        position: "top",
        status: 'success',
        duration: 2000
      }))
    } catch (error: any) {
      dispatch(showSnackBar({
        message: "Don't support",
        position: "top",
        status: 'error',
        duration: 2000
      }))
    }
  };

  return (
    <div className={styles.ClubPage}>
      <div className={styles.WrapHeader}>
        <h2>{t('Invite friends')}</h2>
        <SwitchPanel
          heading=''
          onSwitch={setActive}
          options={trainingOptions}
          active={active}
          fontSize={15}
        />
      </div>
      <div className={styles.Content}>
        { active?.id === '1' ? <InviteContent /> : <FriendsContent /> }
      </div>
      <div className={styles.Buttons}>
        <button
          onClick={handleInviteFriend}
          className={styles.Primary}>
          {t('Invite friends')}
        </button>
        <button
          onClick={handleCopyToClipboard}
          className={styles.Secondary}
        >
          <div className={styles.ChainIcon}>
            { icons.chainIcon }
          </div>
        </button>
      </div>
      <SnackBar />
      <Navbar />
    </div>
  )
}
