import airdropTransactionService from "../services/airdropTransaction.service.ts";
import {SendTransactionRequest, useTonAddress, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import {beginCell, Cell} from "@ton/ton";
import {updateUserData} from "../store/user.ts";
import {useState} from "react";
import {useAppDispatch} from "./hooks.ts";

export const useSendAirdropTonTransaction = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const sourceAddressUserFriendly = useTonAddress();
    const sourceAddress = useTonAddress(false);
    const wallet = useTonWallet();
    const dispatch = useAppDispatch()
    const [tonConnectUI] = useTonConnectUI();

    const onSendTransaction = async () => {
        if (!wallet || !sourceAddressUserFriendly) {
            return;
        }

        try {
            setIsLoading(true);
            const { content: {invoice_id, destination_wallet, grams} } = await airdropTransactionService.precheckout(sourceAddressUserFriendly, sourceAddress);

            const body = beginCell()
                .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
                .storeStringTail(invoice_id) // write our text comment
                .endCell();

            const transaction: SendTransactionRequest = {
                validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
                messages: [
                    {
                        address: destination_wallet, // message destination in user-friendly format
                        amount: grams, // Toncoin in nanotons
                        payload: body.toBoc().toString("base64"),
                    },
                ],
            };

            const resultTransaction = await tonConnectUI.sendTransaction(transaction);
            const extHash = Cell.fromBase64(resultTransaction.boc).hash().toString('hex')

            const {content: {state, user}} = await airdropTransactionService.process(extHash, resultTransaction.boc);

            if (state === 'completed') {
                dispatch(updateUserData(user));
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false)
            console.log({error})
        }
    }

    return {
        onSendTransaction,
        isLoading,
    }
}