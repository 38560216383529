import { CSSProperties } from 'react';
import styles from './DayCard.module.css';
import icons from "../../../utils/icons";
import { useTranslation } from 'react-i18next';

type DayCardProps =  {
  day: number,
  reward: number,
  isCompleted: boolean,
  isToday: boolean,
  style?: CSSProperties
};

export default function DayCard({ day, reward, isCompleted, isToday, style }:DayCardProps) {
 const {t} = useTranslation('ns1');

  return (
    <div
      className={`${styles.DayCard} ${isCompleted ? styles.isCompleted : ""} ${isToday ? styles.isToday : ""}`}
      style={style}
    >
      <div className={styles.Icon}>
        <icons.xpIcon width={60} height={60} />
      </div>
      <div className={styles.Info}>
        <div className={styles.Day}>
          {t('Day')} {day}
        </div>
        <div className={styles.Reward}>
          {reward}
        </div>
      </div>
    </div>
  )
}
